import * as FA from '@fortawesome/free-solid-svg-icons';
import {AppRoutes} from "./app-routes";
import {Usuario} from "./user";
import {MODAL_VOLUNTARIO} from "../views/estadisticas/Predefinidas";

const vol = new Usuario().voluntario();

export const headerMenu = [
    {
        route: AppRoutes.Voluntarios + "/" + (vol !== null ? vol.uuid : ""),
        name: "Mi Perfil",
        icon: FA.faUser,
    },
    {
        route: AppRoutes.Estadisticas,
        name: "Mis Estadísticas",
        icon: FA.faChartArea,
        accion: () => {
            if (vol) sessionStorage.setItem(MODAL_VOLUNTARIO, vol.uuid);
        }
    }
];

export const sideMenu = [
    {
        route: AppRoutes.Panel,
        name: "Panel de Control",
        color: "primary",
        icon: FA.faDesktop,
        bottom: false,
    },
    {
        route: AppRoutes.Operativos,
        name: "Operativos",
        color: "orange",
        icon: FA.faCompass,
        bottom: false,
    },
    {
        route: AppRoutes.Formacion,
        name: "Formación",
        color: "default",
        icon: FA.faBookOpen,
        bottom: false
    },
    {
        route: AppRoutes.Material,
        name: "Material",
        color: "green",
        icon: FA.faToolbox,
        bottom: false,
    },
    {
        route: AppRoutes.Vehiculos,
        name: "Vehículos",
        color: "danger",
        icon: FA.faCar,
        bottom: false,
    },
    {
        route: AppRoutes.Voluntarios,
        name: "Voluntarios",
        color: "teal",
        icon: FA.faUserShield,
        bottom: false,
    },
    {
        route: AppRoutes.Estadisticas,
        name: "Estadísticas",
        color: "indigo",
        icon: FA.faChartBar,
        bottom: false
    },
    // Bottom
    {
        route: AppRoutes.Alertas,
        name: "Alertas del 112",
        icon: FA.faBell,
        bottom: true,
    },
    {
        route: AppRoutes.Avisos,
        name: "Avisos Particulares",
        icon: FA.faPhone,
        bottom: true,
    },
    {
        route: AppRoutes.Nido,
        name: "Nido",
        icon: FA.faBuilding,
        bottom: true,
    },
    {
        route: AppRoutes.Noticias,
        name: "Noticias",
        icon: FA.faNewspaper,
        bottom: true,
    },
    {
        route: AppRoutes.Documentacion,
        name: "Documentación",
        icon: FA.faBook,
        bottom: true,
    },
];
