import {Moment} from "moment";
import {Voluntario} from "./Voluntario";
import {VehiculoParte} from "./Vehiculos";
import {Concello} from "./Concellos";
import {Material} from "./Material";
import {Emergencia} from "./Emergencias";

export interface Operativo {
    id: number;
    uuid: string;
    codigo: string;
    emergencia: Emergencia | null;
    nombre: string;
    descripcion: string;
    horaNido: Moment;
    horaInicio: Moment | null;
    horaFin: Moment | null;
    organizacion: string | null;
    concello: Concello | null;
    entidades: string[];
    lugar: string | null;
    tipo: OperativoTipo | null;
    notas: boolean;
    fechaPublicacion: Moment | null;
    inscripciones: number | null;
}

export interface OperativoLista extends Operativo {
    participa: boolean;
    coordinador: Voluntario;
    totalVoluntarios: number;
    estado: string;
    inscripciones: number;
}


export interface OperativoCrear {
    nombre: string;
    descripcion: string;
    horaNido: Moment | null;
    tipo?: OperativoTipo;
    concello?: Concello;
    horaInicio?: Moment;
    notas: boolean;
    fechaPublicacion: Moment | null;
}

export interface OperativoVoluntario {
    voluntario: Voluntario;
    horas: OperativoVoluntarioHoras[];
    coordinacion: number;
    cargo: string | null;
    notificaciones: boolean;
    material: OperativoVoluntarioMaterial[];
    nota: string | null;
}

export interface OperativoVoluntariosHistorico {
    id: number;
    voluntario: Voluntario;
    operativo: Operativo;
    coordinacion: number;
    cargo: string | null;
    notificaciones: boolean;
    nota: string | null;
}

export interface OperativoVoluntarioExterno {
    nombre: string;
    horas: OperativoVoluntarioHoras[];
    coordinacion: number;
    cargo: string | null;
    material: OperativoVoluntarioMaterial[];
    concello: Concello | null;
}

export interface OperativoVehiculo extends VehiculoParte {
}

export interface OperativoInforme {
    id: number;
    estado: string;
    informe: string;
    archivos: OperativoInformeArchivo[];
}

export interface OperativoInformeArchivo {
    id: number;
    nombre: string;
    archivo: string;
}

export interface OperativoLimites {
    id: number;
    fechaApertura: Moment | null;
    fechaCierre: Moment | null;
    maxVoluntarios: number;
    bloqueado: boolean;
    autorizados: null | Voluntario[];
}

export interface OperativoTipo {
    id: number;
    nombre: string;
}

export interface OperativoVoluntarioHoras {
    horaInicio: Moment;
    horaFin: Moment;
}

export interface OperativoMaterial {
    material: Material;
    cantidad: number;
    incidencias: string | null;
}

export interface OperativoVoluntarioMaterial {
    material: Material;
    operativoVoluntario?: OperativoVoluntariosHistorico;
    cantidad: number;
    incidencias: string | null;
}

export interface OperativoMensaje {
    mensaje: string;
    nombreArchivo: string | null;
    archivo: string | null;
}

interface IOperativosLlenos {
    [id: number]: string
}

export const OperativosLlenos: IOperativosLlenos = {
    0: "Abierto",
    1: "El operativo está actualmente bloqueado a nuevas inscripciones.",
    2: "Se ha alcanzado el número máximo de voluntarios que pueden participar.",
    3: "La hora límite para apuntarse al operativo ha pasado.",
    4: "Se necesita un conductor para el operativo, y tú no estás autorizado para conducir.",
    5: "Es demasiado pronto para anotarse al operativo; espera un poco más.",
    6: "No estás autorizado para anotarte a este tipo de operativos"
}