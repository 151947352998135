//@ts-ignore
import {store} from 'react-notifications-component';

export const n = (title: string, message: string, type: string, duration: number = 5000) => {
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: true,
            showIcon: true
        }
    });
};