export enum AppRoutes {
    Panel = '/panel',
    Operativos = '/operativos',
    Material = '/material',
    Vehiculos = '/vehiculos',
    Voluntarios = '/voluntarios',
    Estadisticas = '/estadisticas',
    Formacion = '/formacion',

    Alertas = '/alertas',
    Avisos = '/avisos', 
    Nido = '/nido',
    Noticias = '/noticias',
    Documentacion = '/documentacion',

    Acceso = '/acceso',
    Exit = '/salir'
}
