import React from "react";

interface GorroProps {
  size: "xs" | "sm" | "md" | "lg" | "xl";
}

export class GorroNavidad extends React.Component<GorroProps> {
  render() {
    //return <span className={"xmas-hat-" + this.props.size}/>;
    return <></>;
  }
}