import React from "react";
import {RouteComponentProps} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

import {
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
  } from "reactstrap";

import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment, {Moment} from "moment";
import { Cursos, CursosLimites, CursosTipo } from "../../models/Formacion";
import { BaseURL, Headers } from "../../http";
import { AppRoutes, n } from "../../const";

interface NuevaProps extends RouteComponentProps {
    mostrar: boolean;
    cancelar(): void;
}

interface NuevaState {
    edicion: {
        curso: Cursos | null;
        horaInicio: Moment;
        horaFin: Moment;
    }    
    cursos: Cursos[];
    bloqueado: boolean;
    tipos: CursosTipo[];
    tipoSeleccionado: CursosTipo | undefined;
    mostrarComboCursos: boolean;
    mostrarNuevoCurso: boolean;
    nuevoCurso: Cursos | null;
}

const estadoInicial: NuevaState = {
    edicion: {
        curso: null,
        horaInicio: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss")),
        horaFin: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss")),
    },
    cursos: [], 
    bloqueado: false,
    tipos: [],
    tipoSeleccionado: undefined,
    mostrarComboCursos: true,
    mostrarNuevoCurso: false,
    nuevoCurso: null,
}

export class NuevaFormacion extends React.Component<NuevaProps, NuevaState> {
    constructor(props: NuevaProps) {
        super(props);
        this.state = estadoInicial;
    }

    private bloqueado = React.createRef<HTMLInputElement>();

    private crearEdicionCurso() {

        fetch(BaseURL + "/cursos/edicion", {
            headers: Headers,
            method: "PUT",
            body: JSON.stringify(this.state.edicion)
        })
        .then(res => res.json())
        .then(json => {
            if (this.bloqueado.current?.checked) {
                fetch(BaseURL + "/cursos/edicion/" + json.uuid + "/limites", {headers: Headers})
                  .then(r => r.json())
                  .then((limites: CursosLimites) => {
                        limites.bloqueado = true;
                        fetch(BaseURL + "/cursos/edicion/" + json.uuid + "/limites", {
                        headers: Headers,
                        method: "PUT",
                        body: JSON.stringify(limites)
                        }).then( () => {
                            this.setState( () => this.props.history.push(AppRoutes.Formacion + "/" + json.uuid));
                        });
                  });
            } else {
                this.setState( () => this.props.history.push(AppRoutes.Formacion + "/" + json.uuid));
            }
            n("Listo", "Nueva edición del curso creada", "success");
            this.props.cancelar();
            this.setState({tipoSeleccionado: undefined, 
                           edicion: {curso: null, 
                                     horaInicio: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss")),
                                     horaFin: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss"))}});
        });
    }

    private obtenerCursos = (tipo?: CursosTipo) => {
        if (tipo === null || tipo === undefined) {
            return;
        }
        this.setState({
            tipoSeleccionado: tipo,
            edicion: {
                curso: null,
                horaInicio: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss")),
                horaFin: moment.utc(moment.utc().format("YYYY-MM-DD HH:mm:ss")),
            },
            mostrarNuevoCurso: false, mostrarComboCursos: true });
        fetch(BaseURL + "/cursos/" + tipo.id, {headers: Headers})
            .then(res => res.json())
            .then(res => {
                this.setState({cursos: res}); 
                this.state.cursos.forEach(curso => {
                    if(curso.nombre === this.state.nuevoCurso?.nombre) {
                        this.setState({edicion: {...this.state.edicion, curso: curso}});
                    }
                });
            });
    }

    private anadirCurso = () => {
        fetch(BaseURL + "/cursos", {
            headers: Headers,
            method: "PUT",
            body: JSON.stringify(this.state.nuevoCurso)
        }).then(() => {
            this.obtenerCursos(this.state.tipoSeleccionado);
            this.setState({ mostrarNuevoCurso: false, mostrarComboCursos: true })
        });
    }

    componentDidMount(): void {
        this.setState(estadoInicial, () => fetch(BaseURL + "/cursos/tipos", {headers: Headers})
            .then(res => res.json())
            .then(res => {
                this.setState({tipos: res});
            }));
    }

    render() {
        return <Modal
          className="modal-dialog-centered" isOpen={this.props.mostrar}
          toggle={() => this.props.cancelar()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">Nueva edición de curso</h4>
                <button aria-label="Cerrar"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.cancelar()}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Nav className="nav-fill flex-column flex-md-row mb-4" id="tabs-icons-text" pills role="tablist" >
                    {this.state.tipos.map((prop, key) => {
                        return (
                            <NavItem id={"item" + key}>
                                <NavLink
                                    className={"mb-sm-3 mb-md-0" + (this.state.tipoSeleccionado?.id === prop.id ? " active" : (this.state.tipoSeleccionado === undefined ? " highlight" : ""))}
                                    onClick={() => {
                                        this.obtenerCursos(prop);
                                    }}
                                    role="tab"
                                    href="#"
                                >
                                    <FontAwesomeIcon icon={FA.faCompass}/> {prop.nombre}
                                </NavLink>
                            </NavItem> 
                        );
                    })} 
                </Nav>
                
                <Row>
                    <Col xs="12" md="12">
                        {this.state.mostrarComboCursos ?
                            <InputGroup>
                                <label className="form-control-label" htmlFor="input-nombre">
                                    Curso
                                </label>
                                <Select
                                    value={this.state.edicion.curso ? {
                                        label: this.state.edicion.curso?.nombre,
                                        value: this.state.edicion.curso?.id
                                    } : null}

                                    options={this.state.cursos.map(curso => ({ label: curso.nombre, value: curso.id }))}
                                    placeholder="Curso" className="select-input" classNamePrefix="select-input-prefix"
                                    onChange={(e: any | null | undefined) => {
                                        if (!e) {
                                            e = null;
                                        }

                                        let curso: Cursos | null | undefined = null;
                                        if (e) {
                                            curso = this.state.cursos.find(v => v.id === e.value);
                                        }
                                        if (curso === undefined) {
                                            curso = null;
                                        }
                                        this.setState({ edicion: { ...this.state.edicion, curso: curso } });
                                    }}
                                /> 
                                {(this.state.tipoSeleccionado?.id === 1 || this.state.tipoSeleccionado === undefined) ? <></> :
                                    <Button color="link" variant="outline-secondary"
                                        onClick={() => this.setState({ mostrarNuevoCurso: true, mostrarComboCursos: false })}
                                        disabled={this.state.tipoSeleccionado === undefined} >
                                        <FontAwesomeIcon icon={FA.faPlus}/> Añadir nuevo
                                    </Button>
                                }
                            </InputGroup>
                        : <></>}
                    </Col>
                </Row>

                
                { this.state.mostrarNuevoCurso ? <Row>
                    <Col xs="12" md="12">
                        <InputGroup>
                            <Input placeholder="Nombre nuevo curso" 
                                   onChange={e => {
                                       this.setState({
                                            nuevoCurso: {...this.state.nuevoCurso, nombre: e.target.value, tipo: this.state.tipoSeleccionado}
                                        });
                                    }} 
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="secondary" variant="outline-secondary" onClick={() => this.anadirCurso()}><FontAwesomeIcon icon={FA.faCheck} /></Button>
                                <Button color="secondary" variant="outline-secondary" onClick={() => this.setState({ mostrarNuevoCurso: false, mostrarComboCursos: true })}><FontAwesomeIcon icon={FA.faTimes} /></Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row> : <></>}
                <br></br>
                <Row>
                    <Col xs="12" md="6">
                        <FormGroup>
                        <label className="form-control-label" htmlFor="input-fecha">
                            Fecha inicio
                        </label>
                        <InputGroup className="input-group-alternative" id="input-fecha">
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon={FA.faCalendar}/>
                            </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                            inputProps={{placeholder: "Fecha inicio"}}
                            value={this.state.edicion.horaInicio.local()}
                            onChange={(e: any) => {
                                if (typeof e === typeof "" || e === null || e === undefined) {
                                    return;
                                }
                                if (e !== null) {
                                    e.set('second', 0).set('millisecond', 0);
                                }
                                this.setState({edicion: {...this.state.edicion, horaInicio: e}});
                            }}
                            />
                        </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                        <FormGroup>
                        <label className="form-control-label" htmlFor="input-fecha">
                            Fecha fin
                        </label>
                        <InputGroup className="input-group-alternative" id="input-fecha">
                            <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon={FA.faCalendar}/>
                            </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                            inputProps={{placeholder: "Fecha fin"}}
                            value={this.state.edicion.horaFin.local()}
                            onChange={(e: any) => {
                                if (typeof e === typeof "" || e === null || e === undefined) {
                                    return;
                                }
                                if (e !== null) {
                                    e.set('second', 0).set('millisecond', 0);
                                }
                                this.setState({edicion: {...this.state.edicion, horaFin: e}});
                            }}
                            />
                        </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{paddingTop: 5, cursor: "pointer"}} onClick={() => {
                    if (this.bloqueado.current) {
                        this.bloqueado.current.checked = !this.bloqueado.current.checked;
                    }
                    }}>
                    <Col xs="4" sm="4" md="4" lg="2" xl="2">
                        <label className="custom-toggle">
                        <input type="checkbox" onClick={e => e.preventDefault()} ref={this.bloqueado}/>
                        <span className="custom-toggle-slider rounded-circle"/>
                        </label>
                    </Col>
                    <Col xs="8" sm="8" md="8" lg="10" xl="10">
                        Crear bloqueado
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button color="link" data-dismiss="modal" type="button" className="text-warning"
                        onClick={() => this.props.cancelar()}>
                    Cerrar
                </Button>
                <Button className="ml-auto" color="warning" type="button"
                    onClick={() => this.crearEdicionCurso()} >
                    Crear
                </Button>
            </div>
        </Modal>;
    }

}

export default NuevaFormacion;