import React from "react";
import ReactGA from 'react-ga';
import {GOOGLE_ANALYTICS} from "../const/specs";
import {RouteComponentProps} from "react-router";

ReactGA.initialize(GOOGLE_ANALYTICS);

const withTracker = (WrappedComponent: any, options = {}) => {
    const trackPage = (page: string) => {
        ReactGA.set({
            page,
            ...options,
        });
        ReactGA.pageview(page);
    };

    return class extends React.Component<RouteComponentProps> {
        componentDidMount() {
            const page = this.props.location.pathname + this.props.location.search;
            trackPage(page);
        }

        componentDidUpdate(prevProps: RouteComponentProps) {
            const currentPage = prevProps.location.pathname + prevProps.location.search;
            const nextPage = this.props.location.pathname + this.props.location.search;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withTracker;
