import React from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";

import {AccesoComps} from "../../Acceso";
import {n} from "../../../../const";
import {BaseURL} from "../../../../http";


interface ResetProps extends AccesoComps {
}

interface ResetState {
  formulario: CambioFormulario;
  cargando: boolean;
  invalidos: string[];
}

interface CambioFormulario {
  yago: string;
}


export class Reset extends React.Component<ResetProps, ResetState> {
  constructor(props: ResetProps) {
    super(props);
    this.state = {formulario: {yago: ""}, cargando: false, invalidos: []};
  }

  componentDidMount(): void {
    this.setState({formulario: {yago: ""}, cargando: false, invalidos: []});
  }

  private registro(): void {
    if (this.state.cargando || this.state.invalidos.length > 5)
      return;

    this.setState({cargando: true}, () => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          yago: this.state.formulario.yago
        })
      };
      fetch(BaseURL + "/usuarios/reset", requestOptions)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            if (response.data.codigo === 201) {
              n("Listo", "Si los datos son correctos, se ha enviado un mensaje por Telegram con las instrucciones", "success");
              this.props.cambiarPagina("acceso");
            }
          }
          this.setState({cargando: false});
        });
    });
  }

  render() {
    return <Card className="bg-secondary shadow border-0">
      <CardBody className="px-lg-5 py-lg-5">
        <div className="text-center text-muted mb-4">
          Solicita un cambio de contraseña
        </div>
        <Form role="form">
          <FormGroup className="mb-3">
            <InputGroup
              className={"input-group-alternative" + (this.state.invalidos.indexOf('yago') > -1 ? " form-control-alternative-error" : "")}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i><FontAwesomeIcon icon={FA.faUser}/></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Yago"
                autoComplete="pcivil-yago"
                onChange={e => {
                  let invalidos = [...this.state.invalidos];
                  if (e.target.value === "" && this.state.invalidos.indexOf("yago") === -1)
                    invalidos = [...this.state.invalidos, "yago"];
                  else if (e.target.value !== "" && this.state.invalidos.indexOf("yago") > -1)
                    invalidos.splice(this.state.invalidos.indexOf("yago"));

                  this.setState({
                    formulario: {
                      ...this.state.formulario,
                        yago: e.target.value
                    },
                    invalidos: invalidos
                  })
                }}
                disabled={this.state.cargando}
                required/>
            </InputGroup>
          </FormGroup>
          <div className="text-center">
            <Button
              className="my-4"
              color="warning"
              type="button"
              disabled={this.state.invalidos.length > 0 || this.state.cargando}
              onClick={e => {
                e.preventDefault();
                this.registro();
              }}
            >
              {this.state.cargando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Solicitar Reset</>}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>;
  }
}