import React from 'react';

import {
    Container,
    Row,
    Col
} from "reactstrap";

import {Footer} from './navigation';

export const AuthLayout: React.FC = ({children}) => {
    React.useEffect(() => {
        document.body.classList.add("bg-default");
    }, []);

    return (
        <>
            <div className="main-content">
                <div className="header bg-gradient-info py-7 py-lg-5">
                    <Container>
                        <div className="header-body text-center mb-7">
                            <Row className="justify-content-center">
                                <Col lg="5" md="6">
                                    <h1 className="text-white">Administración LORO</h1>
                                    <p className="text-lead text-white">
                                        AVPC de Santiago de Compostela
                                    </p>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-default"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </div>
                {/* Page content */}
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        {children}
                    </Row>
                </Container>
            </div>
            <Footer/>
        </>
    );
};
