import React from "react";
import {RouteComponentProps} from 'react-router';

import {Button, Col, FormGroup, Input, InputGroup, Modal, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import ReactDatetime from "react-datetime";
import moment from "moment";

import {dni, email, movil, yago} from "../../comps/utils/validadores";
import {AppRoutes} from "../../const";
import {BaseURL, Error as E, Headers} from "../../http";
import {VoluntarioCrear} from "../../models";
import {permiso, Permisos} from "../../const/permisos";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const datos = [
  {nombre: "Luke", apellidos: "Skywalker"},
  {nombre: "Íñigo", apellidos: "Montoya"},
  {nombre: "Jack", apellidos: "Sparrow"},
  {nombre: "Harry", apellidos: "Potter"},
  {nombre: "Homer", apellidos: "Simpson"},
  {nombre: "Bob", apellidos: "Esponja"},
];


interface NuevoProps extends RouteComponentProps {
  mostrar: boolean;

  cancelar(): void;
}

interface NuevoState {
  voluntario: VoluntarioCrear | null;
  creando: boolean;
  invalidos: string[];
  ultimo: string | null;
  aleatorio: number
}


export class Nuevo extends React.Component<NuevoProps, NuevoState> {
  constructor(props: NuevoProps) {
    super(props);
    this.state = {
      voluntario: null,
      creando: false,
      invalidos: [],
      ultimo: null,
      aleatorio: Math.floor(Math.random() * datos.length)
    };
  }

  private invalidar(campo: string): void {
    let invalidos = this.state.invalidos;
    if (invalidos.indexOf(campo) > -1)
      return;
    invalidos.push(campo);
    this.setState({invalidos: invalidos});
  }

  private validar(campo: string): void {
    let invalidos = this.state.invalidos;
    let indice = invalidos.indexOf(campo);
    if (indice === -1)
      return;
    invalidos.splice(indice, 1);
    this.setState({invalidos: invalidos});
  }

  private valido(): boolean {
    if (this.state.voluntario === null)
      return false;
    if (!permiso(Permisos.gestionVoluntarios))
      return false;

    // Campos requeridos para todos: yago, nombre, apellidos, movil
    if (this.state.voluntario.yago === "" || this.state.voluntario.nombre === ""
      || this.state.voluntario.apellidos === "" || this.state.voluntario.telefonos.length === 0)
      return false;

    // Campos requeridos para los no BI: dni, fechaNacimiento, email, direccion
      if ((!this.state.voluntario.yago.startsWith("BI") && !this.state.voluntario.yago.startsWith("CAP")) && (this.state.voluntario.dni === ""
      || this.state.voluntario.fechaNacimiento === null || this.state.voluntario.email === ""
      || this.state.voluntario.direccion === ""))
      return false;

    return this.state.invalidos.length === 0;
  }

  componentDidUpdate(prevProps: Readonly<NuevoProps>, prevState: Readonly<NuevoState>, snapshot?: any): void {
    if (!this.props.mostrar) {
      if (this.state.voluntario !== null) {
        this.setState({voluntario: null});
      }
      return;
    }
    if (this.state.voluntario === null)
      this.setState({
        voluntario: {
          yago: "",
          nombre: "",
          apellidos: "",
          dni: "",
          usuario: "",
          email: "",
          telefonos: [],
          fechaNacimiento: null,
          lugarNacimiento: "Santiago de Compostela",
          direccion: "",
          alta: moment.utc(),
          conducir: "",
          sexo: "M",
          autorizado: false,
          autorizadoDesa: false,
          autorizadoMotosierra: false,
          tutor: false,
        },
        aleatorio: Math.floor(Math.random() * datos.length)
      });
  }

  private crear() {
    if (!this.valido() || this.state.creando || this.state.voluntario === null)
      return;

    this.setState({creando: true}, () => {
      fetch(BaseURL + "/voluntarios/alta", {
        headers: Headers,
        method: "POST",
        body: JSON.stringify(this.state.voluntario)
      })
        .then(res => res.json())
        .then(json => {
          this.props.cancelar();
          this.setState({creando: false}, () => {
            sessionStorage.setItem("print", "1");
            this.props.history.push(AppRoutes.Voluntarios + "/" + json.uuid);
          });
        })
        .catch(error => E(error));
    });
  }

  componentDidMount(): void {
    if (this.state.ultimo === null)
      fetch(BaseURL + "/voluntarios/?page=0&size=1&orden=yago&desc=1", {headers: Headers})
        .then(res => res.json())
        .then(json => {
          this.setState({ultimo: json[0].yago.slice(1)})
        })
  }

  render() {
    return <Modal className="modal-dialog-centered modal-lg"
                  isOpen={this.props.mostrar}
                  toggle={() => this.props.cancelar()}
                  onOpened={() => this.componentDidMount()}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="modal-title-default">
          Nuevo Voluntario
        </h4>
        <button aria-label="Cerrar" className="close" data-dismiss="modal" type="button"
                onClick={() => this.props.cancelar()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="pl-lg-4">
          <Row>
            <Col md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-yago">
                  YAGO
                </label>
                <InputGroup
                  className={"input-group-alternative" + (this.state.invalidos.indexOf('yago') > -1 ? " form-control-alternative-error" : "")}
                  id="input-yago"
                >
                  <Input
                    placeholder={this.state.ultimo !== null ? "Y" + (parseInt(this.state.ultimo) + 1).toString() : "ZZZ"}
                    className="form-control-alternative"
                    id="input-yago2" type="text"
                    onChange={e => {
                      if (this.state.voluntario === null)
                        return;
                      if (e.target.value === "" || !yago(e.target.value))
                        this.invalidar("yago");
                      else
                        this.validar("yago");
                      this.setState({
                        voluntario: {
                          ...this.state.voluntario,
                          yago: e.target.value
                        }
                      })
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-nombre">
                  Nombre
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('nombre') > -1 ? " form-control-alternative-error" : "")}
                  id="input-nombre"
                  placeholder={datos[this.state.aleatorio].nombre}
                  type="text"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (e.target.value === "") this.invalidar("nombre");
                    else this.validar("nombre");
                    this.setState({
                      voluntario: {
                        ...this.state.voluntario,
                        nombre: e.target.value
                      }
                    })
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-apellidos">
                  Apellidos
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('apellidos') > -1 ? " form-control-alternative-error" : "")}
                  id="input-apellidos"
                  placeholder={datos[this.state.aleatorio].apellidos}
                  type="text"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (e.target.value === "") this.invalidar("apellidos");
                    else this.validar("apellidos");
                    this.setState({
                      voluntario: {
                        ...this.state.voluntario,
                        apellidos: e.target.value
                      }
                    })
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-sexo">
                  Sexo
                </label>
                <Select
                  closeMenuOnSelect components={makeAnimated()}
                  options={[{value: "M", label: "Hombre"}, {value: "F", label: "Mujer"}]}
                  value={this.state.voluntario?.sexo === "M" ? {value: "M", label: "Hombre"} : {
                    value: "F",
                    label: "Mujer"
                  }}
                  onChange={(e: any | null | undefined) => {
                    if (this.state.voluntario === null || e === undefined || e === null)
                      return;
                    this.setState({
                      voluntario: {...this.state.voluntario, sexo: e.value}
                    })
                  }}
                  placeholder="M/F"
                  className="select-input"
                  classNamePrefix="select-input-prefix"
                  noOptionsMessage={() => "..."}
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-dni">
                  Documento Nacional de Identidad
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('dni') > -1 ? " form-control-alternative-error" : "")}
                  id="input-dni"
                  placeholder="DNI"
                  type="text"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (!this.state.voluntario.yago.startsWith("BI") && (e.target.value === "" || !dni(e.target.value))) {
                      this.invalidar("dni");
                    } else {
                      this.validar("dni");
                    }
                    this.setState({
                      voluntario: {
                        ...this.state.voluntario,
                        dni: e.target.value.toUpperCase()
                      }
                    })
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-fecha">
                  Fecha de Nacimiento
                </label>
                <InputGroup
                  className={"input-group-alternative" + (this.state.invalidos.indexOf('nacimiento') > -1 ? " form-control-alternative-error" : "")}
                  id="input-fecha">
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Fecha de nacimiento",
                      readOnly: true
                    }}
                    timeFormat={false}
                    value={this.state.voluntario?.fechaNacimiento?.local() || undefined}
                    onChange={(e: any) => {
                      if (this.state.voluntario === null)
                        return;
                      if (!this.state.voluntario.yago.startsWith("BI") && typeof e === typeof "") {
                        this.invalidar("nacimiento");
                        e = null;
                      } else {
                        this.validar("nacimiento");
                      }
                      this.setState({voluntario: {...this.state.voluntario, fechaNacimiento: e}});
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4"/>
        <div className="pl-lg-4">
          <Row>
            <Col md="8">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Dirección de Correo Electrónico Personal
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('email') > -1 ? " form-control-alternative-error" : "")}
                  id="input-email"
                  placeholder="Dirección de email personal"
                  type="text"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (!this.state.voluntario.yago.startsWith("BI") && (e.target.value === "" || !email(e.target.value))) {
                      this.invalidar("email");
                    } else {
                      this.validar("email");
                    }
                    this.setState({
                      voluntario: {
                        ...this.state.voluntario,
                        email: e.target.value.toLowerCase()
                      }
                    })
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-movil">
                  Número de Teléfono Móvil
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('movil') > -1 ? " form-control-alternative-error" : "")}
                  id="input-movil"
                  placeholder="Móvil"
                  type="text"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (e.target.value === "" || !movil(e.target.value)) {
                      this.invalidar("movil");
                    } else {
                      this.validar("movil");
                      this.setState({
                        voluntario: {
                          ...this.state.voluntario,
                          telefonos: [{id: -1, alias: "Móvil", numero: parsePhoneNumberFromString(e.target.value, "ES")?.number.toString() || "", primario: true, visible: true}]
                        }
                      })
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-direccion">
                  Dirección Postal
                </label>
                <Input
                  className={"form-control-alternative" + (this.state.invalidos.indexOf('direccion') > -1 ? " form-control-alternative-error" : "")}
                  id="input-direccion"
                  placeholder="Dirección postal"
                  type="textarea"
                  rows="3"
                  onChange={e => {
                    if (this.state.voluntario === null) return;
                    if (!this.state.voluntario.yago.startsWith("BI") && (e.target.value === "" || e.target.value.split('\n').length > 5)) {
                      this.invalidar("direccion");
                    } else {
                      this.validar("direccion");
                    }
                    this.setState({
                      voluntario: {
                        ...this.state.voluntario,
                        direccion: e.target.value
                      }
                    })
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="link" data-dismiss="modal" type="button" className="text-info"
          onClick={() => this.props.cancelar()} disabled={this.state.creando}
        >
          Cerrar
        </Button>
        <Button
          className="ml-auto" color="info" type="button"
          onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
        >
          {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
        </Button>
      </div>
    </Modal>;
  }
}
