import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import JwtDecode from "jwt-decode";

import {AccesoComps} from "../../Acceso";
import {n} from "../../../../const";
import {Voluntario} from "../../../../models";
import {BaseURL} from "../../../../http";
import {clave} from "../../../../comps/utils/validadores";


interface CambioProps extends AccesoComps {
    token: string;
}

interface CambioState {
    formulario: CambioFormulario;
    cargando: boolean;
    invalidos: string[];
}

interface CambioFormulario {
    clave: string;
    clave2: string;
}


export class Cambio extends React.Component<CambioProps, CambioState> {
    constructor(props: CambioProps) {
        super(props);
        this.state = {formulario: {clave: "", clave2: ""}, cargando: false, invalidos: []};

        if (this.props.token === "")
            n("Error", "No se ha recibido ningún token de registro", "danger");
    }

    componentDidMount(): void {
        this.setState({formulario: {clave: "", clave2: ""}, cargando: false, invalidos: []});
    }

    private voluntario = (JwtDecode(this.props.token) as any).usr as Voluntario;

    private registro(): void {
        if (this.state.cargando || this.state.invalidos.length > 5)
            return;

        if (!clave(this.state.formulario.clave))
            n("Contraseña", "Tiene que tener un mínimo de 8 caracteres, y una letra con un número", "warning");
        else if (this.state.formulario.clave !== this.state.formulario.clave2)
            n("Confirmar contraseña", "Las contraseñas no coinciden", "warning");
        else
            this.setState({cargando: true}, () => {
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + this.props.token},
                    body: JSON.stringify({clave: this.state.formulario.clave})
                };
                fetch(BaseURL + "/usuarios/reset", requestOptions)
                    .then(response => response.json())
                    .then(response => {
                        if (!response.success) {
                            if (response.data.codigo === 406) {
                                // La contraseña no cumple los requisitos
                                n("Error", "Esta contraseña no cumple los requisitos mínimos", "danger");
                            }
                        } else {
                            if (response.data.codigo === 200) {
                                n("Listo", "Se ha cambiado la contraseña; ya puedes iniciar sesión", "success");
                                this.props.cambiarPagina("acceso");
                            }
                        }
                        this.setState({cargando: false});
                    })
                  .catch(() => n("Error", "No se ha recibido una respuesta válida", "error"));
            });
    }

    render() {
        return <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                    Cambia tu contraseña
                </div>
                <Form role="form">
                    <Row>
                        <Col md="4">
                            <FormGroup>
                                <Input
                                    disabled type="text"
                                    value={this.voluntario.yago}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="8">
                            <FormGroup>
                                <Input
                                    disabled type="text"
                                    value={this.voluntario.nombre + " " + this.voluntario.apellidos}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="mb-3">
                        <InputGroup
                            className={"input-group-alternative" + (this.state.invalidos.indexOf('clave') > -1 ? " form-control-alternative-error" : "")}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i><FontAwesomeIcon icon={FA.faLock}/></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                placeholder="Nueva contraseña"
                                type="password"
                                autoComplete="pcivil-ncontrasena"
                                onChange={e => {
                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("clave") === -1)
                                        invalidos = [...this.state.invalidos, "clave"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("clave") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("clave"));

                                    this.setState({
                                        formulario: {
                                            ...this.state.formulario,
                                            clave: e.target.value
                                        },
                                        invalidos: invalidos
                                    })
                                }}
                                disabled={this.state.cargando}
                                required/>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <InputGroup
                            className={"input-group-alternative" + (this.state.invalidos.indexOf('clave2') > -1 ? " form-control-alternative-error" : "")}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i><FontAwesomeIcon icon={FA.faLock}/></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Repetir contraseña" type="password"
                                   autoComplete="pcivil-ncontrasena2"
                                   onChange={e => {
                                       let invalidos = [...this.state.invalidos];
                                       if (e.target.value === "" && this.state.invalidos.indexOf("clave2") === -1)
                                           invalidos = [...this.state.invalidos, "clave2"];
                                       else if (e.target.value !== "" && this.state.invalidos.indexOf("clave2") > -1)
                                           invalidos.splice(this.state.invalidos.indexOf("clave2"));

                                       this.setState({
                                           formulario: {
                                               ...this.state.formulario,
                                               clave2: e.target.value
                                           },
                                           invalidos: invalidos
                                       })
                                   }}
                                   onKeyDown={e => {
                                       if (e.key === 'Enter') {
                                           e.preventDefault();
                                           this.registro();
                                       }
                                   }}
                                   disabled={this.state.cargando}
                                   required
                            />
                        </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                        <Button
                            className="my-4"
                            color="warning"
                            type="button"
                            disabled={this.state.invalidos.length > 0 || this.state.cargando}
                            onClick={e => {
                                e.preventDefault();
                                this.registro();
                            }}
                        >
                            {this.state.cargando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Cambiar contraseña</>}
                        </Button>
                    </div>
                </Form>
            </CardBody>
        </Card>;
    }
}