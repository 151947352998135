import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

import "./assets/scss/argon-dashboard-react.scss";
import 'react-notifications-component/dist/theme.css';
import "./assets/scss/editor.scss";
import "./assets/scss/styles.scss";

import App from "./App";
import {Usuario} from "./const/user";

const sentry_key = process.env.REACT_APP_SENTRY_KEY;
const sentry_organization = process.env.REACT_APP_SENTRY_ORG;
const sentry_project = process.env.REACT_APP_SENTRY_PROJECT;

if (process.env.NODE_ENV === "production" && sentry_key !== undefined && sentry_organization !== undefined && sentry_project !== undefined) {
  const vol = new Usuario().voluntario();

  Sentry.init({
    dsn: "https://" + sentry_key + "@" + sentry_organization + ".ingest.sentry.io/" + sentry_project,
    release: 'frontend@' + process.env.REACT_APP_VERSION
  });

  if (vol) {
    Sentry.setUser({email: vol.usuario + "@sdc.gal", id: vol.uuid, username: vol.yago});
  } else {
    Sentry.withScope(scope => scope.setUser(null));
  }
}


ReactDOM.render(<App/>, document.getElementById('pcivil'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
