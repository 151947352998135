import React from "react";
import {RouteComponentProps} from "react-router";
import {OperativoLista, OperativoVehiculo, Vehiculo, VehiculoParte, Voluntario} from "../../models";
import {Button, Col, FormGroup, Input, InputGroup, Modal, Row, UncontrolledTooltip, Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {permiso, Permisos} from "../../const/permisos";
import {BaseURL, Headers} from "../../http";
import {AppRoutes, n} from "../../const";
import Dropzone from "dropzone";
import moment, {Moment} from "moment";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import {Response} from "../../models/Response";
import {Usuario} from "../../const/user";


Dropzone.autoDiscover = false;


interface NuevoProps extends RouteComponentProps {
    mostrar: boolean;

    cancelar(): void;
}

interface NuevoState {
    vehiculo: {
        nombre: string;
        modelo: string;
        matricula: string;
        imagen: string;
        alta: Moment;
        baja: null;
    };
    creando: boolean;
    invalidos: string[];
}

const estadoInicial: NuevoState = {
    vehiculo: {
        nombre: "",
        modelo: "",
        matricula: "",
        imagen: "",
        alta: moment.utc(),
        baja: null
    },
    creando: false,
    invalidos: []
};

const maxKmsParte: number = 120;

export class Nuevo extends React.Component<NuevoProps, NuevoState> {

    constructor(props: NuevoProps) {
        super(props);

        this.state = estadoInicial;
    }

    private inicio(): void {
        let currentSingleFile: any = undefined;
        let elemento = document.getElementById("dropzone-single");
        let zona = document.getElementsByClassName("dz-preview-single")[0] as HTMLElement;
        if (elemento === null || zona === null)
            return;
        new Dropzone(elemento, {
            url: BaseURL + "/azure/blob/imagen",
            headers: {...Headers, 'Content-Type': undefined},
            method: "PUT",
            paramName: "file",
            previewsContainer: zona,
            previewTemplate: zona.innerHTML,
            maxFiles: 1,
            acceptedFiles: "image/*",
            init: function () {
                this.on("addedfile", (file) => {
                    if (currentSingleFile) {
                        this.removeFile(currentSingleFile);
                    }
                    currentSingleFile = file;
                });
            },
        }).on('success', (file, response: Response) => {
            if (!response.success)
                return;
            this.setState({vehiculo: {...this.state.vehiculo, imagen: response.data.descripcion}});
        });
        zona.innerHTML = "";
    }

    private valido(): boolean {
        if (this.state.invalidos.length > 0)
            return false;
        if (this.state.vehiculo.nombre === "" || this.state.vehiculo.matricula === "" || this.state.vehiculo.modelo === "" || this.state.vehiculo.imagen === "")
            return false;
        return permiso(Permisos.gestionVehiculos);
    }

    private crear(): void {
        if (!this.valido())
            return;

        this.setState({creando: true}, () => fetch(BaseURL + "/vehiculos/alta", {
            headers: Headers,
            method: "POST",
            body: JSON.stringify(this.state.vehiculo)
        })
            .then(res => res.json())
            .then((res: Vehiculo) => {
                n("Listo", "Se ha creado el vehículo", "success");
                this.setState(estadoInicial, () => {
                    this.props.cancelar();
                    this.props.history.push(AppRoutes.Vehiculos + "/" + res.uuid)
                });
            })
            .catch(() => n("Error", "Hubo un error creando el vehículo", "danger")));

    }

    componentDidMount(): void {
        this.setState(estadoInicial);
    }

    render() {
        return <Modal
            className="modal-dialog-centered" isOpen={this.props.mostrar}
            toggle={() => this.props.cancelar()}
            onOpened={() => this.inicio()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">
                    Nuevo Vehículo
                </h4>
                <button aria-label="Cerrar"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.cancelar()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Nombre
                            </label>
                            <Input
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('nombre') > -1 ? " form-control-alternative-error" : "")}
                                id="input-nombre"
                                placeholder="Nombre del vehículo"
                                type="text"
                                onChange={e => {
                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("nombre") === -1)
                                        invalidos = [...this.state.invalidos, "nombre"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("nombre") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("nombre"));

                                    this.setState({
                                        vehiculo: {
                                            ...this.state.vehiculo,
                                            nombre: e.target.value
                                        },
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Matricula
                            </label>
                            <Input
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('matricula') > -1 ? " form-control-alternative-error" : "")}
                                id="input-matricula"
                                placeholder="Matrícula del vehículo"
                                type="text"
                                onChange={e => {
                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("matricula") === -1)
                                        invalidos = [...this.state.invalidos, "matricula"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("matricula") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("matricula"));

                                    this.setState({
                                        vehiculo: {
                                            ...this.state.vehiculo,
                                            matricula: e.target.value
                                        },
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Modelo
                            </label>
                            <Input
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('modelo') > -1 ? " form-control-alternative-error" : "")}
                                id="input-modelo"
                                placeholder="Modelo del vehículo"
                                type="text"
                                onChange={e => {
                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("modelo") === -1)
                                        invalidos = [...this.state.invalidos, "modelo"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("modelo") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("modelo"));

                                    this.setState({
                                        vehiculo: {
                                            ...this.state.vehiculo,
                                            modelo: e.target.value
                                        },
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="dropzone dropzone-single mb-3" id="dropzone-single">
                            <div className="fallback">
                                <div className="custom-file">
                                    <input className="custom-file-input" id="projectCoverUploads" type="file"/>
                                    <label className="custom-file-label" htmlFor="projectCoverUploads">
                                        Escoger imagen
                                    </label>
                                </div>
                            </div>
                            <div className="dz-preview dz-preview-single">
                                <div className="dz-preview-cover">
                                    <div className="dz-progress">
                                <span
                                    className="dz-upload"
                                    data-dz-uploadprogress={true}
                                    style={{backgroundColor: "black", height: 5}}
                                />
                                    </div>
                                    <img alt="..." className="dz-preview-img" data-dz-thumbnail=""/>
                                </div>
                            </div>
                            <div className="dz-message">
                                Arrastra una imagen, o clica para seleccionar una
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="link" data-dismiss="modal" type="button" className="text-danger"
                    onClick={() => this.props.cancelar()} disabled={this.state.creando}
                >
                    Cerrar
                </Button>
                <Button
                    className="ml-auto" color="danger" type="button"
                    onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
                >
                    {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
                </Button>
            </div>
        </Modal>;
    }
}


/** NUEVO PARTE **/


interface NuevoParteProps extends NuevoProps {
    vehiculo: Vehiculo;
    voluntario: Voluntario;
}

interface NuevoParteState {
    invalidos: string[];
    creando: boolean;
    parte: VehiculoParte;
    operativos: OperativoLista[];
    usaOperativo: null | OperativoLista;
    voluntarios: Voluntario[];
}

export class NuevoParte extends React.Component<NuevoParteProps, NuevoParteState> {
    constructor(props: NuevoParteProps) {
        super(props);

        this.state = {
            invalidos: [],
            creando: false,
            parte: this.parteInicial,
            operativos: [],
            usaOperativo: null,
            voluntarios: []
        };
    }

    private user = new Usuario().voluntario();

    private parteInicial: VehiculoParte = {
        id: 0,
        uuid: "",
        voluntario: this.props.voluntario,
        vehiculo: this.props.vehiculo,
        kmsInicio: this.props.vehiculo.kmsActuales,
        kmsFin: 0,
        horaInicio: moment.utc(),
        horaFin: moment.utc(),
        motivo: "",
        repostar: null,
        incidencias: null,
        operativo: null,
    };

    private valido(): boolean {
        if (this.state.invalidos.length > 0)
            return false;
        if (this.state.parte.horaFin.isBefore(this.state.parte.horaInicio))
            return false;
        if (this.state.parte.motivo === "" || this.state.parte.kmsFin === 0)
            return false;
        return this.state.parte.repostar === null || this.state.parte.repostar > 0;
    }

    private finalizar(): void {
        this.setState({parte: this.parteInicial, creando: false, usaOperativo: null}, () => {
            this.props.cancelar();
            this.props.history.push(AppRoutes.Vehiculos + "/" + this.props.vehiculo.uuid);
        });
    }

    private crear(): void {
        if (!this.valido())
            return;

        this.setState({
            creando: true,
            parte: {...this.state.parte, operativo: this.state.usaOperativo}
        }, () => fetch(BaseURL + "/vehiculos/" + this.props.vehiculo.uuid + "/partes/alta", {
            headers: Headers,
            method: "POST",
            body: JSON.stringify(this.state.parte)
        })
            .then(res => res.json())
            .then((parte: VehiculoParte) => {
                if (this.state.usaOperativo === null || this.state.usaOperativo.coordinador === null || this.state.usaOperativo.coordinador.uuid !== this.user?.uuid) {
                    this.finalizar();
                } else {
                    fetch(BaseURL + "/operativos/" + this.state.usaOperativo.uuid + "/vehiculos", {headers: Headers})
                        .then(res => res.json())
                        .then((res: OperativoVehiculo[]) => {
                            if (this.state.usaOperativo === null)
                                return;
                            res.push(parte);
                            fetch(BaseURL + "/operativos/" + this.state.usaOperativo.uuid + "/vehiculos", {
                                headers: Headers,
                                method: "POST",
                                body: JSON.stringify(res)
                            })
                                .then(() => {
                                    this.finalizar();
                                })
                        })
                }
            }));
    }

    componentDidMount(): void {
        this.setState({creando: false, invalidos: [], parte: this.parteInicial});

        let filtros: string[] = [];
        filtros.push("estado=BORRADOR");
        filtros.push("participa=1");
        filtros.push("coordina=0,1");
        filtros.push("emergencia=0,1");
        filtros.push("externo=0,1");
        const filtro: string = filtros.join("&");

        fetch(BaseURL + "/operativos?page=0&size=20&orden=horaNido&desc=1&" + filtro, {headers: Headers})
            .then(res => res.json())
            .then(res => this.setState({operativos: res}));

        if (permiso(Permisos.gestionPartes))
            fetch(BaseURL + "/voluntarios/total", {headers: Headers})
                .then(res => res.text())
                .then(res => fetch(BaseURL + "/voluntarios?size=" + res, {headers: Headers})
                    .then(res => res.json())
                    .then(res => this.setState({voluntarios: res})));
    }

    private validarHoras() {
        if (this.state.parte.horaInicio.isAfter(this.state.parte.horaFin)) {
            this.setState({invalidos: [...this.state.invalidos, "horaFin"]});
        } else {
            this.setState({
                invalidos: (() => {
                    let invalidos = [...this.state.invalidos];
                    invalidos.splice(invalidos.indexOf("horaFin"));
                    return invalidos;
                })()
            });
        }
    }

    private formatoVoluntarios(voluntarios: Voluntario[] = this.state.voluntarios) {
        return Array.from(voluntarios.map(prop => ({
            value: prop.uuid,
            label: prop.yago + " - " + (prop.apodo !== null && prop.apodo !== "" ? prop.apodo : prop.nombre)
        })));
    }

    render() {
        return <Modal
            className="modal-dialog-centered" isOpen={this.props.mostrar}
            toggle={() => this.props.cancelar()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">
                    Nuevo Parte - {this.props.vehiculo?.nombre}
                </h4>
                <button aria-label="Cerrar"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.cancelar()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                {permiso(Permisos.gestionPartes) ?
                    <Row>
                        <Col>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-tipo">
                                    Voluntario{" "}
                                    <FontAwesomeIcon icon={FA.faQuestionCircle} id="ayudaVol" data-placement="top"
                                                     style={{borderBottom: "1pt dotted"}}/>
                                    <UncontrolledTooltip delay={0} placement="top" target="ayudaVol">
                                        Permite especificar el conductor del vehículo.
                                    </UncontrolledTooltip>
                                </label>
                                <Select
                                    disabled={this.state.voluntarios.length === 0}
                                    value={this.formatoVoluntarios([this.state.parte.voluntario])[0]}
                                    components={makeAnimated()}
                                    options={this.formatoVoluntarios()}
                                    placeholder="Conductor"
                                    className="select-input"
                                    classNamePrefix="select-input-prefix"
                                    noOptionsMessage={() => "..."}
                                    onChange={(e: any | null | undefined) => {
                                        if (e === undefined || e === null) {
                                            this.setState({
                                                parte: {
                                                    ...this.state.parte,
                                                    voluntario: this.props.voluntario
                                                }
                                            });
                                        } else {
                                            const voluntario = this.state.voluntarios.find(x => x.uuid === e.value);
                                            if (voluntario !== undefined)
                                                this.setState({
                                                    parte: {
                                                        ...this.state.parte,
                                                        voluntario: voluntario
                                                    }
                                                })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row> : <></>}
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Operativo{" "}
                                <FontAwesomeIcon icon={FA.faQuestionCircle} id="ayudaOp" data-placement="top"
                                                 style={{borderBottom: "1pt dotted"}}/>
                                <UncontrolledTooltip delay={0} placement="top" target="ayudaOp">
                                    Esto copiará los datos del operativo al formulario y enlazará el parte al operativo
                                    automáticamente SÓLO si eres el coordinador.
                                </UncontrolledTooltip>
                            </label>
                            <Select
                                isClearable
                                components={makeAnimated()}
                                options={this.state.operativos.map(prop => ({
                                    value: prop.uuid,
                                    label: "[" + prop.codigo + "] " + prop.nombre
                                }))}
                                placeholder="Operativo"
                                className="select-input"
                                classNamePrefix="select-input-prefix"
                                noOptionsMessage={() => "..."}
                                onChange={(e: any | null | undefined) => {
                                    if (e === undefined || e === null) {
                                        this.setState({usaOperativo: null});
                                    } else {
                                        const operativo = this.state.operativos.find(x => x.uuid === e.value);
                                        if (operativo !== undefined)
                                            this.setState({
                                                parte: {
                                                    ...this.state.parte,
                                                    motivo: operativo.nombre,
                                                    horaInicio: moment.utc(operativo.horaNido),
                                                    horaFin: operativo.horaFin !== null ? moment.utc(operativo.horaFin) : this.state.parte.horaFin
                                                },
                                                usaOperativo: operativo
                                            })
                                    }
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Hora Inicio
                            </label>
                            <InputGroup className="input-group-alternative" id="input-horaInicio">
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: "Hora Inicio",
                                        readOnly: true
                                    }}
                                    value={this.state.parte.horaInicio.local()}
                                    onChange={(e: any) => {
                                        if (typeof e === typeof "")
                                            e = null;
                                        if (e !== null)
                                            e.set('second', 0).set('millisecond', 0);
                                        if (e !== null)
                                            this.setState({
                                                parte: {
                                                    ...this.state.parte,
                                                    horaInicio: e
                                                }
                                            }, () => this.validarHoras());
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Hora Fin
                            </label>
                            <InputGroup
                                className={"input-group-alternative" + (this.state.invalidos.indexOf('horaFin') > -1 ? " form-control-alternative-error" : "")}
                                id="input-horaFin">
                                <ReactDatetime
                                    inputProps={{
                                        placeholder: "Hora Fin",
                                        readOnly: true
                                    }}
                                    value={this.state.parte.horaFin.local()}
                                    onChange={(e: any) => {
                                        if (typeof e === typeof "")
                                            e = null;
                                        if (e !== null)
                                            e.set('second', 0).set('millisecond', 0);
                                        if (e !== null)
                                            this.setState({
                                                parte: {
                                                    ...this.state.parte,
                                                    horaFin: e
                                                }
                                            }, () => this.validarHoras());
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-motivo">
                                Motivo
                            </label>
                            <Input
                                value={this.state.parte.motivo}
                                disabled={this.state.usaOperativo !== null}
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('motivo') > -1 ? " form-control-alternative-error" : "")}
                                id="input-motivo"
                                placeholder="Motivo del desplazamiento"
                                type="text"
                                onChange={e => {
                                    if (this.state.usaOperativo)
                                        return;

                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("motivo") === -1)
                                        invalidos = [...this.state.invalidos, "motivo"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("motivo") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("motivo"));

                                    this.setState({
                                        parte: {...this.state.parte, motivo: e.target.value},
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-kmsinicio">
                                Kilómetros Inicio
                            </label>
                            <Input
                                value={this.state.parte.kmsInicio}
                                disabled={this.props.vehiculo.kmsActuales !== 0 && !permiso(Permisos.gestionPartes)}
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('kmsinicio') > -1 ? " form-control-alternative-error" : "")}
                                id="input-kmsinicio"
                                placeholder="Kms Inicio"
                                type="number"
                                onChange={e => {
                                    if (this.props.vehiculo.kmsActuales !== 0 && !permiso(Permisos.gestionPartes))
                                        return;

                                    let invalidos = [...this.state.invalidos];
                                    if (e.target.value === "" && this.state.invalidos.indexOf("kmsinicio") === -1)
                                        invalidos = [...this.state.invalidos, "kmsinicio"];
                                    else if (e.target.value !== "" && this.state.invalidos.indexOf("kmsinicio") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("kmsinicio"));

                                    this.setState({
                                        parte: {...this.state.parte, kmsInicio: parseInt(e.target.value)},
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-kmsfin">
                                Kilómetros Fin
                            </label>
                            <Input
                                value={this.state.parte.kmsFin}
                                className={"form-control-alternative" + (this.state.invalidos.indexOf('kmsfin') > -1 ? " form-control-alternative-error" : "")}
                                id="input-kmsfin"
                                placeholder="Kms Fin"
                                type="number"
                                onChange={e => {
                                    let invalidos = [...this.state.invalidos];
                                    if (parseInt(e.target.value) < this.state.parte.kmsInicio && this.state.invalidos.indexOf("kmsfin") === -1)
                                        invalidos = [...this.state.invalidos, "kmsfin"];
                                    else if (parseInt(e.target.value) >= this.state.parte.kmsInicio && this.state.invalidos.indexOf("kmsfin") > -1)
                                        invalidos.splice(this.state.invalidos.indexOf("kmsfin"));

                                    this.setState({
                                        parte: {...this.state.parte, kmsFin: parseInt(e.target.value)},
                                        invalidos: invalidos
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-repostar">
                                <FontAwesomeIcon icon={FA.faGasPump}/> {this.state.parte.repostar !== null ? "Litros" : ""}
                            </label>
                            <br/>
                            {this.state.parte.repostar === null ? <label className="custom-toggle mt-2">
                                <input type="checkbox" onChange={e => {
                                    this.setState({parte: {...this.state.parte, repostar: 0}});
                                }}/>
                                <span className="custom-toggle-slider rounded-circle"/>
                            </label> : <Input
                                value={this.state.parte.repostar}
                                className={"form-control-alternative" + (this.state.parte.repostar <= 0 ? " form-control-alternative-error" : "")}
                                id="input-repostar"
                                placeholder="Litros"
                                type="number"
                                onChange={e => {
                                    const float = parseFloat(e.target.value);
                                    if (e.target.value === "0" || float < 0) {
                                        this.setState({parte: {...this.state.parte, repostar: null}});
                                    } else {
                                        this.setState({parte: {...this.state.parte, repostar: float}});
                                    }
                                }}
                            />}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-incidencias">
                                Incidencias
                            </label>
                            <Input
                                className="form-control-alternative"
                                value={this.state.parte.incidencias || ""}
                                id="input-incidencias"
                                placeholder="Detalles de las incidencias"
                                rows="2"
                                type="textarea"
                                onChange={e => {
                                    e.preventDefault();
                                    this.setState({
                                        parte: {
                                            ...this.state.parte,
                                            incidencias: e.target.value !== "" ? e.target.value : null
                                        },
                                    });
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {(this.state.parte.kmsFin - this.state.parte.kmsInicio) > maxKmsParte ? 
                            <Alert color="warning">
                                <FontAwesomeIcon icon={FA.faExclamationTriangle}></FontAwesomeIcon>{" "}Se va a guardar un parte con más de {maxKmsParte} kms
                            </Alert>
                        : <></>}
                    </Col>
                </Row>
            </div>
            
            <div className="modal-footer">
                <Button
                    color="link" data-dismiss="modal" type="button" className="text-danger"
                    onClick={() => this.props.cancelar()} disabled={this.state.creando}
                >
                    Cerrar
                </Button>
                <Button
                    className="ml-auto" color="danger" type="button"
                    onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
                >
                    {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear Parte</>}
                </Button>
            </div>
        </Modal>;
    }
}