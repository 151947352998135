import React from "react";
import {Helmet} from "react-helmet";

import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-regular-svg-icons";

export class WIP extends React.Component {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <Helmet>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <title>🤔 - PCivil Santiago</title>
                </Helmet>
                <div className="header bg-gradient-orange pb-8 pt-5 pt-md-8">
                    <Container fluid>
                        <div className="header-body">
                            {/* Card stats */}
                            <Row>
                                <Col>
                                    <h1 className="display-2 text-white">
                                        Ooops
                                    </h1>
                                    <p className="text-white mt-0 mb-5">
                                        Estamos trabajando en esto... Muy pronto podrás disfrutar de esta maravillosa página :)
                                    </p>
                                    <div className="text-white">
                                        <FontAwesomeIcon icon={FA.faSurprise} spin size="10x"/>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </>
        );
    }
}

export default WIP;