import React from 'react';
import {
    Button,
    Input,
    Modal
} from "reactstrap";
import {Operativo, OperativoLista} from "../../../models";
import {BaseURL, Headers} from "../../../http";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {n} from "../../../const";

interface NotasProps {
    operativo: Operativo | OperativoLista | null;

    cerrar(): void;
}

interface NotasState {
    cargando: boolean;
    notas: string | null;
}

export class Notas extends React.Component<NotasProps, NotasState> {
    private readonly titulo: React.RefObject<HTMLHeadingElement>;

    constructor(props: Readonly<NotasProps>) {
        super(props);
        this.titulo = React.createRef();

        this.state = {
            cargando: false,
            notas: null
        }
    }

    private guardar() {
        if (!this.props.operativo) return;
        
        if (this.state.notas === null || this.state.notas.length === 0) {
            this.props.cerrar();
            return;
        }

        this.setState({cargando: true}, () => {
            if (!this.props.operativo) return;
            fetch(BaseURL + "/operativos/" + this.props.operativo.uuid + "/voluntarios/nota", {
                headers: Headers, method: "POST",
                body: this.state.notas === null ? JSON.stringify(this.state.notas) : this.state.notas
            })
              .then(() => this.setState({cargando: false}, () => {
                  n("Listo", "Nota guardada", "default");
                  this.props.cerrar();
              }));
        });
    }

    render() {
        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={this.props.operativo !== null}
                toggle={() => this.props.cerrar()}
            >
                <div className="modal-header">
                    <h4 className="modal-title" id="modal-title-default" ref={this.titulo}>
                        {this.props.operativo?.nombre || "Cargando..."}
                    </h4>
                    <button
                        aria-label="Cerrar" className="close" data-dismiss="modal" type="button"
                        onClick={() => this.props.cerrar()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>
                        ¿Quieres especificar alguna nota para el coordinador con respecto al operativo? Por ejemplo,
                        hora de llegada posterior a hora nido, o necesidad de marchar antes.
                    </p>
                    <Input
                      maxLength={500}
                      disabled={this.state.cargando}
                      className="form-control-alternative"
                      id="input-descripcion"
                      placeholder="Opcional"
                      value={this.state.notas || ""}
                      rows="3"
                      type="textarea"
                      onChange={e => this.setState({notas: e.target.value.length === 0 ? null : e.target.value})}
                    />
                </div>
                <div className="modal-footer">
                    <Button color="link" type="button" style={{color: "var(--warning)"}} disabled={this.state.cargando}
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.cerrar();
                            }}>
                        Cerrar
                    </Button>
                    <Button
                        className="ml-auto" color="warning" data-dismiss="modal" type="button"
                        disabled={this.state.cargando} onClick={(e) => {
                            e.preventDefault();
                            this.guardar();
                        }}
                    >
                        {this.state.cargando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
                    </Button>
                </div>
            </Modal>
        );
    }
}
