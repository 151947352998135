import React from "react";

import {
    Card,
    CardBody,
    Form,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FAB from "@fortawesome/free-brands-svg-icons";

import {AccesoComps, TelegramUser} from "../../Acceso";
import {n} from "../../../../const";
import {BaseURL} from "../../../../http";
import ReactGA from "react-ga";


interface TelegramProps extends AccesoComps {
    telegram: TelegramUser;

    cambiarRegistro(token: string): Promise<void>;

    acceso(token: string): void;
}

interface TelegramState {
    primero: boolean;
}


export class Telegram extends React.Component<TelegramProps, TelegramState> {
    constructor(props: TelegramProps) {
        super(props);

        this.setState({primero: true});

        if (props.telegram === undefined || props.telegram === null)
            n("Error", "No se ha enviado el usuario de Telegram", "danger");
    }

    componentDidMount(): void {
        this.setState({primero: true}, () => this.telegram());
    }

    private registro(token: string) {
        this.props.cambiarRegistro(token).then(() => this.props.cambiarPagina("registro"));
    }

    private telegram(): void {
        let cuerpo = this.props.telegram;
        if (!this.state.primero)
            cuerpo.hash = "";
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(cuerpo)
        };
        ReactGA.event({
            category: 'Acceso',
            action: 'Telegram'
        });
        fetch(BaseURL + "/usuarios/telegram", requestOptions)
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    if (response.data.codigo === 401) {
                        // Bot no puede mandar mensajes
                        n("Error", "No se ha podido enviar el mensaje", "danger");
                    } else if (response.data.codigo === 404) {
                        // Usuario no existe
                        n("Error", "El usuario no existe", "danger");
                    } else if (response.data.codigo === 409) {
                        // Los datos no son de Telegram
                        n("Error", "Hubo un problema al validar tu solicitud", "danger");
                    } else if (response.data.codigo === 418) {
                        // Usuario no activo
                        n("Error", "Este usuario no está activo", "danger");
                    } else if (response.data.codigo === 420) {
                        if (this.state.primero)
                            this.setState({primero: false}, () => {
                                if (this.props.telegram !== undefined)
                                    n("Hola " + this.props.telegram.first_name,
                                        "Te hemos enviado un mensaje por Telegram. Sigue las instrucciones indicadas",
                                        "info");
                            });
                        // Esperando datos...
                        setTimeout(() => {
                            this.telegram();
                        }, 500);
                    }
                    if (response.data.codigo !== 420)
                        this.props.cambiarPagina("acceso");
                } else {
                    if (response.data.codigo === 200) {
                        // Correcto, hay token, hay que guardarlo y entrar
                        this.props.acceso(response.data.descripcion);
                    } else if (response.data.codigo === 201) {
                        // Usuario dado de alta en Telegram, mostrar registro
                        this.registro(response.data.descripcion);
                        n("Identidad verificada", "Por favor, completa tu registro", "success");
                    }
                }
            });
    }

    render() {
        return <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                    Esperando confirmación de Telegram...
                </div>
                <Form role="form">
                    <div className="text-center">
                        <div className="pulse">
                            <FontAwesomeIcon icon={FAB.faTelegram} size="10x"/>
                        </div>
                    </div>
                </Form>
            </CardBody>
        </Card>;
    }
}