import {Button, Col, FormGroup, InputGroup, Modal, Nav, NavItem, NavLink, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {permiso, Permisos} from "../../const/permisos";
import React from "react";
import moment from "moment";
import {AppRoutes} from "../../const";
import {BaseURL, Headers} from "../../http";
import {Vehiculo, Voluntario as VoluntarioM} from "../../models";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {ModalProps, ModalState} from "./types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {Usuario} from "../../const/user";

export const MODAL_VOLUNTARIO = "modal_voluntario";
export const MODAL_VEHICULOS = "modal_vehiculos";

export class Predefinidas extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {mostrar: false, tipo: "global", voluntario: null, vehiculo: null};

    this.cambiarVisible = this.cambiarVisible.bind(this);
    this.toEstadisticas = this.toEstadisticas.bind(this);
    this.comprobarSesion = this.comprobarSesion.bind(this);
  }

  private user = new Usuario().voluntario();

  private INICIO = moment.utc('2020-01-01').startOf('day');
  private HOY = moment().endOf('day');

  private ANUAL_I = moment().startOf('day').add(-1, 'year');
  private ANUAL_F = this.HOY.clone();

  private ANO_ACTUAL_I = moment().startOf('year');
  private ANO_ACTUAL_F = moment().endOf('year');
  private MES_ACTUAL_I = moment().startOf('month');
  private MES_ACTUAL_F = moment().endOf('month');

  public cambiarVisible() {
    this.setState({mostrar: !this.state.mostrar});
  }

  private VOLUNTARIOS: VoluntarioM[] = [];
  private VEHICULOS: Vehiculo[] = [];

  private static ESTADISTICAS_GLOBALES = {
    numOpsTipo: "inicio={INICIO}&fin={HOY}&xClave=operativos_tipos&yClave=operativos&yValor=total",
    tiempoOpsTipo: "inicio={INICIO}&fin={HOY}&xClave=operativos_tipos&yClave=operativos&yValor=total",
    kmsVehiculo: "inicio={INICIO}&fin={HOY}&xClave=vehiculos&yClave=operativos&yValor=total",
    kmsVehiculoTipo: "inicio={INICIO}&fin={HOY}&xClave=vehiculos&yClave=operativos&yValor=total&yAgruparClave0=operativos_tipos",
    numVol: "inicio={INICIO}&fin={HOY}&xClave=tiempo&xOpcion=quarter&yClave=voluntarios&yValor=total",
    numVehiculos: "inicio={INICIO}&fin={HOY}&xClave=tiempo&xOpcion=quarter&yClave=vehiculos&yValor=total",
  }

  private static ESTADISTICAS_VOLUNTARIOS = {
    numOps: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=total&" +
      "yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}",
    numOpsTipo: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=total&" +
      "yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}&yAgruparClave1=operativos_tipos",
    tiempoOps: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=tiempo&" +
      "yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}",
    tiempoOpsTipo: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=tiempo&" +
      "yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}&yAgruparClave1=operativos_tipos",
    partesKms: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&yValor=kms&" +
      "yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}",
    partesKmsVehiculos: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&" +
      "yValor=kms&yAgruparClave0=voluntarios&yAgruparValores0={VOL_ID}&yAgruparClave1=vehiculos"
  }

  private static ESTADISTICAS_VEHICULOS = {
    numPartes: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&yValor=total&" +
      "yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}",
    numPartesVol: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&yValor=total&" +
      "yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}&yAgruparClave1=voluntarios",
    tiempoPartes: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&" +
      "yValor=tiempo&yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}",
    tiempoPartesVol: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&" +
      "yValor=tiempo&yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}&yAgruparClave1=voluntarios",
    kmsPartes: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&yValor=kms&" +
      "yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}",
    kmspartesVol: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&" +
      "yValor=kms&yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}&yAgruparClave1=voluntarios",
    litrosPartes: "inicio={ANUAL_I}&fin={ANUAL_FIN}&xClave=tiempo&xOpcion=month&yClave=vehiculos_partes&" +
      "yValor=litros&yAgruparClave0=vehiculos&yAgruparValores0={VEH_ID}"
  }

  private static ESTADISTICAS_ANUALES = {
    numOps: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=total",
    numOpsSemana: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=week&yClave=operativos&yValor=total",
    tiempoOps: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=tiempo",
    tiempoOpsSemana: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=week&yClave=operativos&yValor=tiempo",
    numOpsVol: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=total" +
      "&yAgruparClave0=voluntarios",
    tiempoOpsVol: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=tiempo" +
      "&yAgruparClave0=voluntarios",
    numOpsTipo: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=total" +
      "&yAgruparClave0=operativos_tipos",
    tiempoOpsTipo: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=tiempo&xOpcion=month&yClave=operativos&yValor=tiempo" +
      "&yAgruparClave0=operativos_tipos",
    kmsVehiculo: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&yValor=kms",
    litrosVehiculo: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&yValor=kms",
    kmsVehiculoVoluntario: "inicio={ANO_ACTUAL_I}&fin={ANO_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&" +
      "yValor=kms&yAgruparClave0=voluntarios"

  }

  private static ESTADISTICAS_MENSUALES = {
    numOpsVol: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=voluntarios&yClave=operativos&yValor=total",
    tiempoOpsVol: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=voluntarios&yClave=operativos&yValor=tiempo",
    numOpsTipo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=operativos_tipos&yClave=operativos&yValor=total",
    tiempoOpsTipo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=operativos_tipos&yClave=operativos&yValor=tiempo",
    numOpsVolTipo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=operativos_tipos&yClave=operativos&yValor=total&" +
      "yAgruparClave0=voluntarios",
    tiempoOpsVolTipo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=operativos_tipos&yClave=operativos&" +
      "yValor=tiempo&yAgruparClave0=voluntarios",
    kmsVehiculo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&yValor=kms",
    litrosVehiculo: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&yValor=kms",
    kmsVehiculoVoluntario: "inicio={MES_ACTUAL_I}&fin={MES_ACTUAL_F}&xClave=vehiculos&yClave=vehiculos_partes&" +
      "yValor=kms&yAgruparClave0=voluntarios"
  }

  private toEstadisticas(f: string): string {
    return AppRoutes.Estadisticas + "?" + f
      .replace("{INICIO}", "" + this.INICIO.unix())
      .replace("{HOY}", "" + this.HOY.unix())
      .replace("{ANUAL_I}", "" + (this.ANUAL_I.isAfter(moment.utc('2019-31-12')) ? this.ANUAL_I.unix() : moment.utc('2020-01-01').unix()))
      .replace("{ANUAL_FIN}", "" + this.ANUAL_F.unix())
      .replace("{VOL_ID}", "" + this.state.voluntario?.id)
      .replace("{VEH_ID}", "" + this.state.vehiculo?.id)
      .replace("{ANO_ACTUAL_I}", "" + this.ANO_ACTUAL_I.unix())
      .replace("{ANO_ACTUAL_F}", "" + this.ANO_ACTUAL_F.unix())
      .replace("{MES_ACTUAL_I}", "" + this.MES_ACTUAL_I.unix())
      .replace("{MES_ACTUAL_F}", "" + this.MES_ACTUAL_F.unix());
  }

  private comprobarSesion() {
    const VOL = sessionStorage.getItem(MODAL_VOLUNTARIO);
    if (VOL) {
      const voluntario = this.VOLUNTARIOS
        .filter(v => permiso(Permisos.verEstadisticasVoluntarios) || this.user?.uuid === v.uuid)
        .find(v => v.uuid === VOL);
      sessionStorage.removeItem(MODAL_VOLUNTARIO);
      if (voluntario) {
        this.setState({mostrar: true, tipo: "voluntario", voluntario: voluntario});
        return;
      }
    }

    const VEHICULO = sessionStorage.getItem(MODAL_VEHICULOS);
    if (VEHICULO) {
      const vehiculo = this.VEHICULOS.find(v => v.uuid === VEHICULO);
      sessionStorage.removeItem(MODAL_VEHICULOS);
      if (vehiculo) {
        this.setState({mostrar: true, tipo: "vehiculo", vehiculo: vehiculo});
        return;
      }
    }
  }

  componentDidMount() {
    if (this.VOLUNTARIOS.length === 0) {
      fetch(BaseURL + "/voluntarios/total" + (!permiso(Permisos.verEstadisticasVoluntarios) ? "?baja=0" : ""), {headers: Headers})
        .then(res => res.text())
        .then(t => {
            fetch(BaseURL + "/voluntarios?size=" + t + "&page=0&orden=yago" + (!permiso(Permisos.verEstadisticasVoluntarios) ? "&baja=0" : ""), {headers: Headers})
              .then(res => res.json())
              .then((res: VoluntarioM[]) => {
                res.forEach(v => this.VOLUNTARIOS.push(v));
                if (!permiso(Permisos.verEstadisticasVoluntarios)) {
                  this.setState({voluntario: new Usuario().voluntario()}, () => this.comprobarSesion());
                } else {
                  this.comprobarSesion();
                }
              })
          }
        );
    }

    if (this.VEHICULOS.length === 0) {
      fetch(BaseURL + "/vehiculos" + (!permiso(Permisos.gestionVehiculos) ? "/activos" : ""), {headers: Headers})
        .then(res => res.json())
        .then((res: Vehiculo[]) => {
            res.forEach(v => this.VEHICULOS.push(v));
            this.comprobarSesion();
          }
        );
    }

    if (this.VOLUNTARIOS.length > 0 && this.VEHICULOS.length > 0) {
      this.comprobarSesion();
    }
  }

  componentDidUpdate(prevProps: Readonly<ModalProps>, prevState: Readonly<ModalState>, snapshot?: any) {
    this.comprobarSesion();
  }

  private voluntarioASelect(v: VoluntarioM) {
    return {value: v.id, label: v.yago + " - " + v.nombre + " " + v.apellidos}
  }

  private voluntariosASelect() {
    return this.VOLUNTARIOS
      .filter(v => permiso(Permisos.verEstadisticasVoluntarios) || v.uuid === this.user?.uuid)
      .map(v => this.voluntarioASelect(v));
  }

  private vehiculoASelect(v: Vehiculo) {
    return {value: v.id, label: v.nombre + " - " + v.modelo};
  }

  private vehiculosASelect() {
    return this.VEHICULOS.map(v => this.vehiculoASelect(v));
  }

  private estadistica(texto: string, link: string, requiere?: "voluntario" | "vehiculo") {
    return <Button
      color="link" style={{color: "var(--indigo)"}} tag={Link}
      disabled={requiere === "voluntario" ? !this.state.voluntario : requiere === "vehiculo" ? !this.state.vehiculo : false}
      to={this.toEstadisticas(link)} onClick={() => this.setState({mostrar: false})}
    >
      {texto}
    </Button>;
  }

  private estadisticasGlobales() {
    return <Row>
      <Col>
        <ul>
          <li>
            {this.estadistica("Número de operativos realizados por tipo", Predefinidas.ESTADISTICAS_GLOBALES.numOpsTipo)}
            <ul>
              <li>
                {this.estadistica("Tiempo en operativos por tipo", Predefinidas.ESTADISTICAS_GLOBALES.tiempoOpsTipo)}
              </li>
            </ul>
          </li>
          <li>
            {this.estadistica("Kilómetros recorridos por cada vehículo", Predefinidas.ESTADISTICAS_GLOBALES.kmsVehiculo)}
            <ul>
              <li>
                {this.estadistica("Kilómetros recorridos por vehículo en tipos de operativo", Predefinidas.ESTADISTICAS_GLOBALES.kmsVehiculoTipo)}
              </li>
            </ul>
          </li>
          <li>
            {this.estadistica("Número de voluntarios en activo por trimestre", Predefinidas.ESTADISTICAS_GLOBALES.numVol)}
          </li>
          <li>
            {this.estadistica("Número de vehículos por trimestre", Predefinidas.ESTADISTICAS_GLOBALES.numVehiculos)}
          </li>
        </ul>
      </Col>
    </Row>;
  }

  private estadisticasVoluntario() {
    return <>
      <Row>
        <Col>
          <FormGroup>
            <InputGroup
              className={"input-group-alternative" + (!this.state.voluntario ? " form-control-alternative-error" : "")}>
              <Select
                isDisabled={!permiso(Permisos.verEstadisticasVoluntarios)}
                value={this.state.voluntario ? this.voluntarioASelect(this.state.voluntario) : null}
                options={this.voluntariosASelect()} isClearable={permiso(Permisos.verEstadisticasVoluntarios)}
                components={makeAnimated()}
                placeholder="Voluntario" className="select-input" classNamePrefix="select-input-prefix"
                onChange={(e: any | null | undefined) => {
                  if (!e) {
                    e = null;
                  }

                  let voluntario: VoluntarioM | null | undefined = null;
                  if (e) {
                    voluntario = this.VOLUNTARIOS.find(v => v.id === e.value);
                  }
                  if (voluntario === undefined) {
                    voluntario = null;
                  }
                  this.setState({voluntario: voluntario});
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>
              {this.estadistica("Número de operativos", Predefinidas.ESTADISTICAS_VOLUNTARIOS.numOps, "voluntario")}
              <ul>
                <li>{this.estadistica("... agrupados por tipo", Predefinidas.ESTADISTICAS_VOLUNTARIOS.numOpsTipo, "voluntario")}</li>
              </ul>
            </li>
            <li>
              {this.estadistica("Tiempo en operativos", Predefinidas.ESTADISTICAS_VOLUNTARIOS.tiempoOps, "voluntario")}
              <ul>
                <li>{this.estadistica("... agrupados por tipo", Predefinidas.ESTADISTICAS_VOLUNTARIOS.tiempoOpsTipo, "voluntario")}</li>
              </ul>
            </li>
            {this.state.voluntario?.autorizado || permiso(Permisos.gestionPartes) ?
              <li>
                {this.estadistica("Kilómetros conducidos", Predefinidas.ESTADISTICAS_VOLUNTARIOS.partesKms, "voluntario")}
                <ul>
                  <li>
                    {this.estadistica("... agrupados por vehículo", Predefinidas.ESTADISTICAS_VOLUNTARIOS.partesKmsVehiculos, "voluntario")}
                  </li>
                </ul>
              </li> : <></>}
          </ul>
        </Col>
      </Row>
    </>;
  }

  private estadisticasVehiculo() {
    return <>
      <Row>
        <Col>
          <FormGroup>
            <InputGroup
              className={"input-group-alternative" + (!this.state.vehiculo ? " form-control-alternative-error" : "")}>
              <Select
                value={this.state.vehiculo ? this.vehiculoASelect(this.state.vehiculo) : null}
                options={this.vehiculosASelect()} isClearable
                components={makeAnimated()}
                placeholder="Vehiculo" className="select-input" classNamePrefix="select-input-prefix"
                onChange={(e: any | null | undefined) => {
                  if (!e) {
                    e = null;
                  }

                  let vehiculo: Vehiculo | null | undefined = null;
                  if (e) {
                    vehiculo = this.VEHICULOS.find(v => v.id === e.value);
                  }
                  if (vehiculo === undefined) {
                    vehiculo = null;
                  }
                  this.setState({vehiculo: vehiculo});
                }}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>
              {this.estadistica("Número de partes", Predefinidas.ESTADISTICAS_VEHICULOS.numPartes, "vehiculo")}
              {permiso(Permisos.verEstadisticasVoluntarios) ? <ul>
                <li>
                  {this.estadistica("... agrupados por conductor", Predefinidas.ESTADISTICAS_VEHICULOS.numPartesVol, "vehiculo")}
                </li>
              </ul> : <></>}
            </li>
            <li>
              {this.estadistica("Tiempo en movimiento", Predefinidas.ESTADISTICAS_VEHICULOS.tiempoPartes, "vehiculo")}
              {permiso(Permisos.verEstadisticasVoluntarios) ? <ul>
                <li>
                  {this.estadistica("... agrupado por conductor", Predefinidas.ESTADISTICAS_VEHICULOS.tiempoPartesVol, "vehiculo")}
                </li>
              </ul> : <></>}
            </li>
            <li>
              {this.estadistica("Kilómetros recorridos", Predefinidas.ESTADISTICAS_VEHICULOS.kmsPartes, "vehiculo")}
              {permiso(Permisos.verEstadisticasVoluntarios) ? <ul>
                <li>
                  {this.estadistica("... agrupados por conductor", Predefinidas.ESTADISTICAS_VEHICULOS.kmspartesVol, "vehiculo")}
                </li>
              </ul> : <></>}
            </li>
            <li>
              {this.estadistica("Litros de combustible consumidos", Predefinidas.ESTADISTICAS_VEHICULOS.litrosPartes, "vehiculo")}
            </li>
          </ul>
        </Col>
      </Row>
    </>;
  }

  private estadisticasAnuales() {
    return <Row>
      <Col>
        <ul>
          <li>
            {this.estadistica("Número de operativos realizados", Predefinidas.ESTADISTICAS_ANUALES.numOps)}
            <ul>
              <li>
                {this.estadistica("Tiempo en operativos", Predefinidas.ESTADISTICAS_ANUALES.tiempoOps)}
              </li>
            </ul>
          </li>
          <li>
            {this.estadistica("Número de operativos realizados en cada semana", Predefinidas.ESTADISTICAS_ANUALES.numOpsSemana)}
            <ul>
              <li>
                {this.estadistica("Tiempo en operativos en cada semana", Predefinidas.ESTADISTICAS_ANUALES.tiempoOpsSemana)}
              </li>
            </ul>
          </li>
          {permiso(Permisos.verEstadisticasVoluntarios) ? <>
            <li>
              {this.estadistica("Número de operativos realizados por cada voluntario", Predefinidas.ESTADISTICAS_ANUALES.numOpsVol)}
              <ul>
                <li>
                  {this.estadistica("Tiempo en operativos por cada voluntario", Predefinidas.ESTADISTICAS_ANUALES.tiempoOpsVol)}
                </li>
              </ul>
            </li>
          </> : <></>}
          <li>
            {this.estadistica("Número de operativos realizados por tipo", Predefinidas.ESTADISTICAS_ANUALES.numOpsTipo)}
            <ul>
              <li>
                {this.estadistica("Tiempo en operativos por tipo", Predefinidas.ESTADISTICAS_ANUALES.tiempoOpsTipo)}
              </li>
            </ul>
          </li>
          <li>
            {this.estadistica("Kilómetros realizados por cada vehículo", Predefinidas.ESTADISTICAS_ANUALES.kmsVehiculo)}
            <ul>
              <li>
                {this.estadistica("Litros consumidos por cada vehículo", Predefinidas.ESTADISTICAS_ANUALES.litrosVehiculo)}
              </li>
            </ul>
          </li>
          {permiso(Permisos.verEstadisticasVoluntarios) ? <li>
            {this.estadistica("Kilómetros realizados por cada vehículo agrupados por voluntario", Predefinidas.ESTADISTICAS_ANUALES.kmsVehiculoVoluntario)}
          </li> : <></>}
        </ul>
      </Col>
    </Row>;
  }

  private estadisticasMensuales() {
    return <Row>
      <Col>
        <ul>
          {permiso(Permisos.verEstadisticasVoluntarios) ? <>
            <li>
              {this.estadistica("Número de operativos realizados por cada voluntario", Predefinidas.ESTADISTICAS_MENSUALES.numOpsVol)}
              <ul>
                <li>
                  {this.estadistica("Tiempo en operativos por cada voluntario", Predefinidas.ESTADISTICAS_MENSUALES.tiempoOpsVol)}
                </li>
              </ul>
            </li>
          </> : <></>}
          <li>
            {this.estadistica("Número de operativos realizados por tipo", Predefinidas.ESTADISTICAS_MENSUALES.numOpsTipo)}
            <ul>
              <li>
                {this.estadistica("Tiempo en operativos por tipo", Predefinidas.ESTADISTICAS_MENSUALES.tiempoOpsTipo)}
              </li>
            </ul>
          </li>
          {permiso(Permisos.verEstadisticasVoluntarios) ? <>
            <li>
              {this.estadistica("Número de operativos realizados por tipo agrupados por voluntario", Predefinidas.ESTADISTICAS_MENSUALES.numOpsVolTipo)}
              <ul>
                <li>
                  {this.estadistica("Tiempo en operativos por tipo agrupados por voluntario", Predefinidas.ESTADISTICAS_MENSUALES.tiempoOpsVolTipo)}
                </li>
              </ul>
            </li>
          </> : <></>}
          <li>
            {this.estadistica("Kilómetros realizados por cada vehículo", Predefinidas.ESTADISTICAS_MENSUALES.kmsVehiculo)}
            <ul>
              <li>
                {this.estadistica("Litros consumidos por cada vehículo", Predefinidas.ESTADISTICAS_MENSUALES.litrosVehiculo)}
              </li>
            </ul>
          </li>
          {permiso(Permisos.verEstadisticasVoluntarios) ? <li>
            {this.estadistica("Kilómetros realizados por cada vehículo agrupados por voluntario", Predefinidas.ESTADISTICAS_MENSUALES.kmsVehiculoVoluntario)}
          </li> : <></>}
        </ul>
      </Col>
    </Row>;
  }

  private selector() {
    switch (this.state.tipo) {
      case "global":
        return this.estadisticasGlobales();
      case "voluntario":
        return this.estadisticasVoluntario();
      case "vehiculo":
        return this.estadisticasVehiculo();
      case "mensuales":
        return this.estadisticasMensuales();
      case "anuales":
        return this.estadisticasAnuales();
    }
  }

  render() {
    return <Modal
      className="modal-lg modal-dialog-centered" isOpen={this.state.mostrar}
      toggle={() => this.setState({mostrar: !this.state.mostrar})}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="modal-title-default">Estadísticas Predefinidas</h4>
        <button
          aria-label="Cerrar" className="close" data-dismiss="modal" type="button"
          onClick={() => this.setState({mostrar: !this.state.mostrar})}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Nav className="nav-fill flex-column flex-md-row mb-2" id="tabs-icons-text" pills role="tablist">
          <NavItem><NavLink
            aria-selected={this.state.tipo === "global"} role="tab" href="#"
            className={"mb-sm-3 mb-md-0" + (this.state.tipo === "global" ? " active" : "")}
            style={{
              backgroundColor: (this.state.tipo === "global" ? "var(--indigo)" : undefined),
              color: (this.state.tipo === "global" ? undefined : "var(--indigo)")
            }}
            onClick={() => this.setState({tipo: "global"})}
          >
            <FontAwesomeIcon icon={FA.faGlobe}/> Generales
          </NavLink></NavItem>
          <NavItem><NavLink
            aria-selected={this.state.tipo === "voluntario"} role="tab" href="#"
            className={"mb-sm-3 mb-md-0" + (this.state.tipo === "voluntario" ? " active" : "")}
            style={{
              backgroundColor: (this.state.tipo === "voluntario" ? "var(--indigo)" : undefined),
              color: (this.state.tipo === "voluntario" ? undefined : "var(--indigo)")
            }}
            onClick={() => this.setState({tipo: "voluntario"})}
          >
            <FontAwesomeIcon icon={FA.faUserAlt}/> Voluntarios
          </NavLink></NavItem>
          <NavItem><NavLink
            aria-selected={this.state.tipo === "vehiculo"} role="tab" href="#"
            className={"mb-sm-3 mb-md-0" + (this.state.tipo === "vehiculo" ? " active" : "")}
            style={{
              backgroundColor: (this.state.tipo === "vehiculo" ? "var(--indigo)" : undefined),
              color: (this.state.tipo === "vehiculo" ? undefined : "var(--indigo)")
            }}
            onClick={() => this.setState({tipo: "vehiculo"})}
          >
            <FontAwesomeIcon icon={FA.faCarAlt}/> Vehículos
          </NavLink></NavItem>
        </Nav>
        <Nav className="nav-fill flex-column flex-md-row mb-4" id="tabs-icons-text" pills role="tablist">
          <NavItem><NavLink
            aria-selected={this.state.tipo === "anuales"} role="tab" href="#"
            className={"mb-sm-3 mb-md-0" + (this.state.tipo === "anuales" ? " active" : "")}
            style={{
              backgroundColor: (this.state.tipo === "anuales" ? "var(--indigo)" : undefined),
              color: (this.state.tipo === "anuales" ? undefined : "var(--indigo)")
            }}
            onClick={() => this.setState({tipo: "anuales"})}
          >
            <FontAwesomeIcon icon={FA.faCalendarAlt}/> Anuales
          </NavLink></NavItem>
          <NavItem><NavLink
            aria-selected={this.state.tipo === "mensuales"} role="tab" href="#"
            className={"mb-sm-3 mb-md-0" + (this.state.tipo === "mensuales" ? " active" : "")}
            style={{
              backgroundColor: (this.state.tipo === "mensuales" ? "var(--indigo)" : undefined),
              color: (this.state.tipo === "mensuales" ? undefined : "var(--indigo)")
            }}
            onClick={() => this.setState({tipo: "mensuales"})}
          >
            <FontAwesomeIcon icon={FA.faCalendarDay}/> Mensuales
          </NavLink></NavItem>
        </Nav>
        {this.selector()}
      </div>
      <div className="modal-footer">
        <Button
          color="link" style={{color: "var(--indigo)"}} data-dismiss="modal" type="button"
          onClick={(e) => {
            e.preventDefault();
            this.setState({mostrar: !this.state.mostrar});
          }}
        >
          Cerrar
        </Button>
      </div>
    </Modal>;
  }
}