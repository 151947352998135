import React from "react";

import {Col} from "reactstrap";
import {Helmet} from "react-helmet";

import {Cambio, Login, Registro, Reset, Telegram} from "./_comps/acceso";
import {AppRoutes} from "../../const";
import {Usuario} from "../../const/user";
import {RouteComponentProps} from "react-router";


interface AccesoProps extends RouteComponentProps {
}

interface AccesoState {
  pagina: paginas | null;
  telegram?: TelegramUser;
  token?: string;
}

export interface AccesoComps {
  cambiarPagina(pagina: paginas): void
}

export type paginas = "acceso" | "telegram" | "registro" | "reset" | "cambio";

export interface TelegramUser {
  id: number;
  first_name: string;
  last_name?: string | null;
  username: string;
  photo_url?: string | null;
  hash: string;
}


export class Acceso extends React.Component<AccesoProps, AccesoState> {
  constructor(props: AccesoProps) {
    super(props);
    this.state = {
      pagina: "acceso"
    };

    this.cambioPagina = this.cambioPagina.bind(this);
    this.guardarTelegram = this.guardarTelegram.bind(this);
    this.guardarToken = this.guardarToken.bind(this);
    this.acceder = this.acceder.bind(this);
  }


  cambioPagina(pagina: paginas): void {
    this.props.history.push(AppRoutes.Acceso);
    this.setState({pagina: pagina}, () => {
      switch (pagina) {
        case "registro":
          // Necesita token y telegram
          break;
        case "cambio":
          // Solo necesita token
          this.setState({telegram: undefined});
          break;
        case "telegram":
          // Resetea el token al que reciba
          this.setState({token: undefined});
          break;
        case "reset":
        case "acceso":
          // Ambas son primeras pantallas, generan ambos valores por lo que no los necesitan
          this.setState({telegram: undefined, token: undefined});
          break;
      }
    });
  }

  guardarTelegram(usuario: TelegramUser): Promise<void> {
    return new Promise(resolve => {
      this.setState({telegram: usuario, token: undefined}, () => resolve());
    });
  }

  guardarToken(token: string): Promise<void> {
    return new Promise(resolve => {
      if (token.startsWith("#"))
        token = token.substring(1);
      this.setState({token: token}, () => resolve());
    });
  }

  acceder(token: string): void {
    new Usuario().crear(token);
    const redir = sessionStorage.getItem('redirect') || AppRoutes.Panel;
    sessionStorage.removeItem('redirect');
    window.location.pathname = redir;
  }


  componentDidMount(): void {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;

    if (this.props.location.pathname === (AppRoutes.Acceso + "/tg")) {
      const params = new URLSearchParams(this.props.location.search);
      const id = params.get("id");
      if (id === null) return;
      const first_name = params.get("first_name");
      if (first_name === null) return;
      let last_name: string | null | undefined = params.get("last_name");
      if (last_name === null) last_name = undefined;
      const username = params.get("username");
      if (username === null) return;
      let photo_url: string | null | undefined = params.get("photo_url");
      if (photo_url === null) photo_url = undefined;
      const hash = params.get("hash");
      if (hash === null) return;

      const telegram: TelegramUser = {
        id: parseInt(id),
        first_name: first_name,
        last_name: last_name,
        username: username,
        photo_url: photo_url,
        hash: hash
      };

      this.guardarTelegram(telegram).then(() => this.cambioPagina("telegram"));
      return;
    } else if (this.props.location.pathname === (AppRoutes.Acceso + "/reset")) {
      this.guardarToken(this.props.location.hash).then(() => this.cambioPagina("cambio"));
    }
  }

  render() {
    return <>
      <Helmet>
        <title>Acceso | PCivil Santiago</title>
      </Helmet>
      <Col lg="5" md="7">
        {(() => {
          switch (this.state.pagina) {
            case "telegram":
              if (this.state.telegram !== undefined)
                return <Telegram
                  cambiarPagina={this.cambioPagina}
                  cambiarRegistro={this.guardarToken}
                  telegram={this.state.telegram}
                  acceso={this.acceder}
                />;
              break;
            case "registro":
              if (this.state.token !== undefined && this.state.telegram !== undefined)
                return <Registro
                  cambiarPagina={this.cambioPagina}
                  registro={this.state.token}
                  telegram={this.state.telegram}
                />;
              break;
            case "acceso":
              return <Login
                cambiarPagina={this.cambioPagina}
                cambiarTelegram={this.guardarTelegram}
                acceso={this.acceder}
              />;
            case "reset":
              return <Reset cambiarPagina={this.cambioPagina}/>;
            case "cambio":
              if (this.state.token !== undefined)
              return <Cambio cambiarPagina={this.cambioPagina} token={this.state.token}/>;
          }
        })()}
      </Col>
    </>;
  }
}

export default Acceso;