import {Concello} from "../models";

export enum Tipos {
    Colaboraciones = 1,
    Emergencias = 19,
    RRSS = 51
}

export const TiposExcluidos = [Tipos.Colaboraciones];

export const ConcelloSantiago = 1;
export const ordenConcellos = (concellos: Concello[]) => {
    let nuevos: Concello[] = [];
    concellos.forEach(prop => {
        if (prop.id === ConcelloSantiago) {
            nuevos.push(prop);
            return;
        }
    });
    concellos.forEach(prop => {
        if (prop.id !== ConcelloSantiago) {
            nuevos.push(prop);
        }
    });
    return nuevos;
};

export class Clonar {
    private static A = sessionStorage;
    private static CLAVE = "ops_clonados";

    public static esCopia(uuid: string): boolean {
        const data = this.A.getItem(this.CLAVE);
        if (data === null)
            return false;
        let ops: string[] = JSON.parse(data);
        return ops.indexOf(uuid) > -1;
    }

    public static copiar(uuid: string): void {
        const data = this.A.getItem(this.CLAVE);
        let ops: string[];
        if (data === null)
            ops = [];
        else
            ops = JSON.parse(data);
        ops.push(uuid);
        this.A.setItem(this.CLAVE, JSON.stringify(ops));
    }

    public static eliminarCopia(uuid: string): void {
        const data = this.A.getItem(this.CLAVE);
        if (data === null)
            return;
        let ops: string[] = JSON.parse(data);
        let index = ops.indexOf(uuid);

        if (index > -1) {
            ops.splice(index, 1);
            this.A.setItem(this.CLAVE, JSON.stringify(ops));
        }
    }
}