import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const yago = (yago: string): boolean => {
    const texto = /^[A-Z]+$/;
    const numeros = /^[0-9]+$/;
    if (yago.length < 3) return false;

    const n = yago.substr(yago.length - 3);
    const t = yago.replace(n, "");
    if (t !== "Y" && t !== "TPC" && t !== "BI" && t !== "CAP") return false;
    if (n.length !== 3) return false;

    return texto.test(t) && numeros.test(n);
};

export const apodo = (apodo: string): boolean => {
    const re = /^[0-9a-zA-Z ñç]+$/;
    if (apodo.length < 3 || apodo.length > 15) return false;
    return re.test(apodo);
};

export const dni = (dni: string): boolean => {
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str = dni.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8)) % 23;

    return validChars.charAt(charIndex) === letter;
};

export const email = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const movil = (movil: string): boolean => {
    const phoneNumber = parsePhoneNumberFromString(movil, 'ES');
    if (phoneNumber) {
        return phoneNumber.isValid();
    }
    return false;
};

export const telefono = (telefono: string): boolean => {
    if (telefono === "") return true;
    const re = /^[0-9]+$/;
    if (telefono.length !== 9) return false;
    if (telefono.charAt(0) !== '9' && telefono.charAt(0) !== '8') return false;
    return re.test(String(telefono));
};

export const usuario = (usuario: string) => {
    if (usuario.length < 5) return false;
    return /^[0-9a-zA-Z_]+$/.test(usuario);
};

export const clave = (clave: string) => {
    if (clave.length < 8)
        return false;
    return /[0-9]/.test(clave) && /[A-Za-z]/.test(clave);
};
