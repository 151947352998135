import React from "react";
import { RouteComponentProps } from "react-router";
import { Modal, Row, Col, InputGroup, FormGroup, InputGroupAddon, InputGroupText,
         Button, Table, UncontrolledTooltip, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import { Cursos, FiltrosListado, ListadoCursosPorVoluntario, ListadoVoluntariosPorCurso, ListadoVoluntariosSinBasico, TiposListado } from "../../models/Formacion";
import { Voluntario } from "../../models/Voluntario";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { BaseURL, Headers } from "../../http";
import { AppRoutes } from "../../const";


interface ListadoProps extends RouteComponentProps {
    mostrar: boolean;
    cancelar(): void;
}

interface ListadoState {
    tiposListado: TiposListado[];
    tipoSeleccionado: TiposListado | null;
    filtros: FiltrosListado;
    voluntarios: Voluntario[] | null;
    cursos: Cursos[] | null;
    listadoVoluntariosPorCurso: ListadoVoluntariosPorCurso[] | null;
    listadoCursosPorVoluntario: ListadoCursosPorVoluntario[] | null;
    listadoVoluntariosSinBasico: ListadoVoluntariosSinBasico[] | null;
}

const tiposListado: TiposListado[] = [
    { id: 1, label: "Cursos por voluntario" },
    { id: 2, label: "Voluntarios por curso" },
    { id: 3, label: "Voluntarios sin cursos básicos" },
];

const estadoInicial: ListadoState = {
    tiposListado: tiposListado,
    tipoSeleccionado: {id: 1, label: "Cursos por voluntario"},
    filtros: {
        fechaInicio: moment.utc(moment().startOf('year').format('MM/DD/YYYY')),
        fechaFin: moment.utc(moment.utc().format("YYYY-MM-DD")),
        cursos: null,
        voluntarios: null,
    },
    voluntarios: null,
    cursos: null,
    listadoVoluntariosPorCurso: null,
    listadoCursosPorVoluntario: null,
    listadoVoluntariosSinBasico: null,
}

export class ListadosFormacion extends React.Component<ListadoProps, ListadoState> {

    constructor(props: ListadoProps) {
        super(props);
        this.state = estadoInicial;
    }

    componentDidMount() {
        fetch(BaseURL + "/voluntarios/total", {headers: Headers})
            .then(res => res.text())
            .then(res => fetch(BaseURL + "/voluntarios?orden=yago&baja=0&size=" + res, {headers: Headers})
                .then(res => res.json())
                .then(voluntarios => {
                    this.setState({voluntarios: voluntarios});
                }));
        fetch(BaseURL + "/cursos", { headers: Headers })
            .then(res => res.json())
            .then(cursos => {
                cursos.fecha = moment.utc(cursos.fecha);
                this.setState({ cursos: cursos });
            });
    }

    private getDatosListado = () => {
        if (this.state.tipoSeleccionado?.id === 2) {
            fetch(BaseURL + "/cursos/listado/voluntarios?fechaInicio=" + this.state.filtros.fechaInicio.unix() +
                "&fechaFin=" + this.state.filtros.fechaFin.unix() +
                "&cursos=" + this.state.filtros.cursos, { headers: Headers, method: "GET" })
                .then(res => res.json())
                .then(json => {
                    json.map((prop: any) => {
                        prop.voluntarios.map((prop2: any) => {
                            prop2.edicion.horaInicio = moment.utc(prop2.edicion.horaInicio);
                            prop2.edicion.horaFin = moment.utc(prop2.edicion.horaFin);
                            return prop2;
                        });
                        return prop;
                    });
                    this.setState({ listadoVoluntariosPorCurso: json });
                })
                .catch(() => {
                    console.log("error");
                });
        } else if (this.state.tipoSeleccionado?.id === 1) {
            fetch(BaseURL + "/cursos/listado/cursosVoluntario?fechaInicio=" + this.state.filtros.fechaInicio.unix() +
                "&fechaFin=" + this.state.filtros.fechaFin.unix() +
                "&voluntarios=" + this.state.filtros.voluntarios, { headers: Headers, method: "GET" })
                .then(res => res.json())
                .then(json => {
                    json.map((prop: any) => {
                        prop.cursos.map((prop2: any) => {
                            prop2.edicion.horaInicio = moment.utc(prop2.edicion.horaInicio);
                            prop2.edicion.horaFin = moment.utc(prop2.edicion.horaFin);
                            return prop2;
                        });
                        return prop;
                    });
                    this.setState({ listadoCursosPorVoluntario: json });
                })
                .catch(() => {
                    console.log("error");
                });
        } else if (this.state.tipoSeleccionado?.id === 3) {
            fetch(BaseURL + "/cursos/listado/sinBasico?fechaInicio=" + this.state.filtros.fechaInicio.unix() +
                "&fechaFin=" + this.state.filtros.fechaFin.unix() , { headers: Headers, method: "GET" })
                .then(res => res.json())
                .then(res => {
                    this.setState({ listadoVoluntariosSinBasico: res }, () => console.log("this.statae -> ", this.state.listadoVoluntariosSinBasico));
                })
                .catch(() => {
                    console.log("error");
                });
        }
    }

    private getColorNota(nota: string | null): string {
        if (nota === null) {
            return "default";
        }

        let ok: string[] = ['APTO', 'APROBADO'];
        let ko: string[] = ['NO APTO', 'NON APTO', 'NON APROBADO', 'NO APROBADO', 'SUSPENSO'];

        if (isNaN(+nota)) {
            if (ok.includes(nota.toUpperCase())) {
                return "success";
            } else if (ko.includes(nota.toUpperCase())) {
                return "warning";
            } else {
                return "info";
            }
        } else {
            return +nota < 5 ? "warning" : "success";
        }
    }

    private formatearCursos() {
        return this.state.cursos?.map(curso => ({ value: curso.id, label: curso.nombre }));
    }

    private formatearVoluntarios() {
        return this.state.voluntarios?.map(voluntario => ({ value: voluntario.id, label: voluntario.yago + " - " + voluntario.nombre }));
    }

    render() {
        return <Modal
          className="modal-dialog-centered modal-xl" isOpen={this.props.mostrar}
          toggle={() => this.props.cancelar()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">Listados</h4>
                <button aria-label="Cerrar"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.cancelar()}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col xs="12" md="3">
                        <InputGroup>
                            <label className="form-control-label" htmlFor="input-nombre">
                                Tipo listado
                            </label>
                            <Select
                                value={this.state.tipoSeleccionado ? {
                                label: this.state.tipoSeleccionado.label,
                                value: this.state.tipoSeleccionado.id
                                } : null}

                                options={this.state.tiposListado.map(tipo => ({label: tipo.label, value: tipo.id}))}
                                placeholder="Tipo de listado" className="select-input" classNamePrefix="select-input-prefix"
                                onChange={(e: any | null | undefined) => {
                                if (!e) {
                                    e = null;
                                }

                                let tipo: TiposListado | null | undefined = null;
                                if (e) {
                                    tipo = this.state.tiposListado.find(v => v.id === e.value);
                                }
                                if (tipo === undefined) {
                                    tipo = null;
                                }
                                this.setState({ tipoSeleccionado: tipo });

                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs="12" md="2">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-fecha">
                                Fecha inicio
                            </label>
                            <InputGroup className="input-group-alternative" id="input-fecha">
                                <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon={FA.faCalendar}/>
                                </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                inputProps={{placeholder: "Fecha inicio"}} timeFormat={false}
                                value={this.state.filtros.fechaInicio.local()}
                                onChange={(e: any) => {
                                    if (typeof e === typeof "" || e === null || e === undefined) {
                                        return;
                                    }
                                    if (e !== null) {
                                        e.set('second', 0).set('millisecond', 0);
                                    }
                                    this.setState({filtros: {...this.state.filtros, fechaInicio: e}});
                                }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="2">
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-fecha">
                                Fecha fin
                        </label>
                            <InputGroup className="input-group-alternative" id="input-fecha">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={FA.faCalendar} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <ReactDatetime
                                    inputProps={{ placeholder: "Fecha fin" }} timeFormat={false}
                                    value={this.state.filtros.fechaFin.local()}
                                    onChange={(e: any) => {
                                        this.setState({ filtros: { ...this.state.filtros, fechaFin: e } });
                                    }}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs="12" md="4">
                        {this.state.tipoSeleccionado?.id !== 2 ? 
                            <InputGroup>
                                <label className="form-control-label" htmlFor="input-nombre">
                                    Voluntarios
                                </label>
                                <Select
                                    isMulti
                                    name="voluntarios"
                                    options={this.formatearVoluntarios()}
                                    onChange={value => {
                                        this.setState({ filtros: { ...this.state.filtros, voluntarios: value?.map(e => e.value) } });
                                    }}
                                    isDisabled={this.state.tipoSeleccionado?.id === 3}
                                    className="select-input"
                                    placeholder="Ninguno" 
                                    classNamePrefix="select-input-prefix"
                                    noOptionsMessage={() => "..."}
                                />
                            </InputGroup> 
                       : (this.state.tipoSeleccionado?.id === 2 ?
                            <InputGroup>
                                <label className="form-control-label" htmlFor="input-cursos">
                                    Cursos
                                </label>
                                <Select
                                    isMulti
                                    name="input-cursos"
                                    options={this.formatearCursos()}
                                    onChange={value => {
                                        let idCursos: number[] = [];
                                        value?.forEach(v => {
                                            if (v.value !== undefined) {
                                                idCursos.push(+v.value);
                                            }
                                        });
                                        this.setState({filtros: {...this.state.filtros, cursos: idCursos}});
                                    }}
                                    className="select-input"
                                    placeholder="Ninguno"
                                    classNamePrefix="select-input-prefix"
                                    noOptionsMessage={() => "..."}
                                />
                            </InputGroup>
                        : <></>)}
                    </Col>
                    <Col xs="12" md="1" style={{paddingTop: "33px"}}>
                        <Button color="default" style={{ width: "100%" }}
                            onClick={(e) => {
                                e.preventDefault();
                                this.getDatosListado();
                            }}
                        >
                            <FontAwesomeIcon icon={FA.faSearch}/>{" "}
                        </Button>
                    </Col> 
                </Row>

                {/** Tabla de resultados */}

                <div className="modal-listados">
                    <Col>

                        {this.state.tipoSeleccionado?.id === 2 ?
                            (this.state.listadoVoluntariosPorCurso?.length === 0 ?
                                <h5>No hay resultados para los criterios indicados</h5>
                                : this.state.listadoVoluntariosPorCurso?.map((prop, key) => {
                                    return (<>
                                        <Row style={{ paddingTop: "50px" }}>
                                            <h5 style={{ paddingTop: "9px", paddingLeft: "5px" }}>{" "}{prop.curso.nombre}{" ["}{prop.curso.tipo?.nombre}{"]"}</h5>
                                        </Row>
                                        <Row style={{ paddingTop: "10px" }}>
                                            <Table className="align-items-center" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        {/* Avatar */}
                                                        <th scope="col" />
                                                        {/* Voluntario */}
                                                        <th scope="col">{" "}Voluntario</th>
                                                        {/* Edicion */}
                                                        {prop.curso.tipo?.id === 1 ?
                                                            <th scope="col">{" "}Edición</th>
                                                            : <></>}
                                                        {/* Fecha */}
                                                        <th scope="col">{" "}Fecha</th>
                                                        {/* Nota */}
                                                        <th scope="col">{" "}Nota</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {prop.voluntarios.map((prop2, key2) => {
                                                        return (
                                                            <tr key={key2}>
                                                                <td>
                                                                    <div className="avatar-group" style={{ verticalAlign: "center" }}>
                                                                        <Link
                                                                            className="avatar avatar-sm" onClick={(e: any) => e.preventDefault()}
                                                                            id={"opVoluntario" + key + "Avatar"}
                                                                            to={AppRoutes.Voluntarios + "/" + prop2.voluntario.uuid}
                                                                        >
                                                                            <img alt="..." className="rounded-circle" src={prop2.voluntario.avatar} />
                                                                        </Link>
                                                                        <UncontrolledTooltip delay={0} target={"opVoluntario" + key + "Avatar"}>
                                                                            {prop2.voluntario.nombre + " " + prop2.voluntario.apellidos}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        tag={Link} to={AppRoutes.Voluntarios + "/" + prop2.voluntario.uuid}
                                                                        size="sm" type="button" color="secondary" outline
                                                                    >
                                                                        <span className="mb-0 text-sm">
                                                                            {prop2.voluntario.nombre + " " + prop2.voluntario.apellidos}
                                                                        </span>
                                                                        <small>{" " + prop2.voluntario.yago}</small>
                                                                    </Button>
                                                                </td>
                                                                {prop.curso.tipo?.id === 1 ?
                                                                    <td>{prop2.edicion.edicion}</td>
                                                                    : <></>}
                                                                <td>
                                                                    <strong>{prop2.edicion.horaInicio.local().format("DD/MM/YYYY")}</strong>
                                                                    <br />
                                                        HN: {prop2.edicion.horaInicio.local().format("HH:mm")}
                                                                </td>
                                                                <td>
                                                                    <Badge
                                                                        className="badge-lg"
                                                                        style={{ fontSize: "90%" }}
                                                                        color={(this.getColorNota(prop2.nota))}
                                                                        pill
                                                                    >
                                                                        {prop2.nota}
                                                                    </Badge>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </>)
                                }))
                            : (this.state.tipoSeleccionado?.id === 1 ?
                                (this.state.listadoCursosPorVoluntario?.length === 0 ?
                                    <h5>No hay resultados para los criterios indicados</h5>
                                    : this.state.listadoCursosPorVoluntario?.map((prop, key) => {
                                        return (<>
                                            <Row style={{ paddingTop: "50px" }}>
                                                <div className="avatar-group" style={{ verticalAlign: "center" }}>
                                                    <Link
                                                        className="avatar avatar-sm" onClick={(e: any) => e.preventDefault()}
                                                        id={"opVoluntario" + key + "Avatar"}
                                                        to={AppRoutes.Voluntarios + "/" + prop.voluntario.uuid}
                                                    >
                                                        <img alt="..." className="rounded-circle" src={prop.voluntario.avatar} />
                                                    </Link>
                                                    <UncontrolledTooltip delay={0} target={"opVoluntario" + key + "Avatar"}>
                                                        {prop.voluntario.nombre + " " + prop.voluntario.apellidos}
                                                    </UncontrolledTooltip>
                                                </div>
                                                <h5 style={{ paddingTop: "9px", paddingLeft: "5px" }}>{" "}{prop.voluntario.yago}{" - "}{prop.voluntario.nombre}{" "}{prop.voluntario.apellidos}</h5>
                                            </Row>
                                            <Row style={{ paddingTop: "10px" }}>
                                                <Table className="align-items-center" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            {/* Voluntario */}
                                                            <th scope="col">{" "}Curso</th>
                                                            {/* Fecha */}
                                                            <th scope="col">{" "}Fecha</th>
                                                            {/* Nota */}
                                                            <th scope="col">{" "}Nota</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {prop.cursos.map((prop2, key2) => {
                                                            return (
                                                                <tr key={key2}>
                                                                    <td>{prop2.edicion.curso.nombre}</td>
                                                                    <td>
                                                                        <strong>{prop2.edicion.horaInicio.local().format("DD/MM/YYYY")}</strong>
                                                                        <br />
                                                                HN: {prop2.edicion.horaInicio.local().format("HH:mm")}
                                                                    </td>
                                                                    <td>
                                                                        <Badge
                                                                            className="badge-lg"
                                                                            style={{ fontSize: "90%" }}
                                                                            color={(this.getColorNota(prop2.nota))}
                                                                            pill
                                                                        >
                                                                            {prop2.nota}
                                                                        </Badge>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>

                                                </Table>
                                            </Row>
                                        </>)
                                    }))
                                : (this.state.listadoVoluntariosSinBasico?.length === 0 ?
                                    <h5>No hay resultados para los criterios indicados</h5>
                                    : this.state.listadoVoluntariosSinBasico?.map((prop, key) => {
                                        return (<>
                                            <Row style={{ paddingTop: "50px" }}>
                                                <div className="avatar-group" style={{ verticalAlign: "center" }}>
                                                    <Link
                                                        className="avatar avatar-sm" onClick={(e: any) => e.preventDefault()}
                                                        id={"opVoluntario" + key + "Avatar"}
                                                        to={AppRoutes.Voluntarios + "/" + prop.voluntario.uuid}
                                                    >
                                                        <img alt="..." className="rounded-circle" src={prop.voluntario.avatar} />
                                                    </Link>
                                                    <UncontrolledTooltip delay={0} target={"opVoluntario" + key + "Avatar"}>
                                                        {prop.voluntario.nombre + " " + prop.voluntario.apellidos}
                                                    </UncontrolledTooltip>
                                                </div>
                                                <h5 style={{ paddingTop: "9px", paddingLeft: "5px" }}>{" "}{prop.voluntario.yago}{" - "}{prop.voluntario.nombre}{" "}{prop.voluntario.apellidos}</h5>
                                            </Row>
                                            <Row style={{ paddingTop: "10px" }}>
                                                <Table className="align-items-center" responsive>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            {/* Voluntario */}
                                                            <th scope="col">{" "}Cursos básicos sin realizar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {prop.cursos.map((prop2, key2) => {
                                                            return (
                                                                <tr>
                                                                    <td>{prop2.nombre}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </Row>
                                        </>)
                                    })))} 
                    </Col>
                 </div>
            </div>
            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.cancelar()}
                >Cancelar </Button>
                {this.state.tipoSeleccionado?.id === 3 ? <></> : 
                <Button
                    color="primary"
                    type="button"
                    disabled={false}
                    onClick={ () => 
                        { 
                            let url = "/cursos/listado/voluntarios/exportar";
                            if (this.state.tipoSeleccionado?.id === 1) {
                                url = "/cursos/listado/cursos/exportar";
                            } else if (this.state.tipoSeleccionado?.id === 3) {
                                url = "/cursos/listado/sinBasico/exportar";
                            }

                            fetch(BaseURL + url, {headers: Headers, method: "POST", body: JSON.stringify(this.state.filtros)}) 
                            .then(response => response.blob().then(blob => {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement('a');
                                
                                a.href = url;
                                a.download = 'Listado.xlsx';
                                a.click();
                            }))
                        }
                    }
                    >Exportar</Button>
                }
            </div>
        </Modal>;
    }

}

export default ListadosFormacion;