import React from 'react';
import {Navbar, Sidebar, Footer} from './navigation';

import {Container} from "reactstrap";

export const AppLayout: React.FC = ({children}) => (
    <div>
        <Sidebar/>
        <div className="main-content">
            <Navbar/>
            {children}
            <Container fluid>
                <Footer/>
            </Container>
        </div>
    </div>
);
