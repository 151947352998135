import React from "react";
import {RouteComponentProps} from 'react-router';
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from 'react-select/creatable';
import {Material, MaterialTipos} from "../../models";
import makeAnimated from "react-select/animated";
import {BaseURL, Headers} from "../../http";
import {AppRoutes} from "../../const";
import {permiso, Permisos} from "../../const/permisos";


interface NuevoProps extends RouteComponentProps {
    mostrar: boolean;

    cancelar(): void;
}

interface NuevoState {
    tipos: MaterialTipos[];
    material: Material;
    creando: boolean;
}


export class Nuevo extends React.Component<NuevoProps, NuevoState> {
    constructor(props: NuevoProps) {
        super(props);
        this.state = {
            tipos: [],
            material: {
                id: 0,
                uuid: "",
                tipo: {id: 0, nombre: ""},
                codigo: "",
                activo: true,
                cantidad: 1
            },
            creando: false
        };
    }

    private crear(): void {
        if (!this.valido())
            return;

        this.setState({creando: true});
        if (this.state.material.tipo.id === 0)
            fetch(BaseURL + "/material/tipos", {
                headers: Headers,
                method: "PUT",
                body: JSON.stringify(this.state.material.tipo)
            })
                .then(res => res.json())
                .then((res: MaterialTipos) => {
                    this.setState({material: {...this.state.material, tipo: res}}, () => this.crearMaterial());
                });
        else
            this.crearMaterial();
    }

    private crearMaterial(): void {
        if (!this.valido())
            return;
        fetch(BaseURL + "/material", {headers: Headers, method: "PUT", body: JSON.stringify(this.state.material)})
            .then(res => res.json())
            .then((res: Material) => {
                this.setState({
                    creando: false,
                    material: {
                        id: 0,
                        uuid: "",
                        tipo: {id: 0, nombre: ""},
                        codigo: "",
                        activo: true,
                        cantidad: 1
                    },
                }, () => {
                    this.props.cancelar();
                    this.props.history.push(AppRoutes.Panel)
                    this.props.history.push(AppRoutes.Material + "/" + res.uuid)
                });
            });
    }

    private valido(): boolean {
        if (this.state.material.tipo.id === 0 && this.state.material.tipo.nombre === "")
            return false;
        if (this.state.material.codigo === "")
            return false;
        return permiso(Permisos.gestionMaterial);

    }

    componentDidMount(): void {
        fetch(BaseURL + "/material/tipos", {headers: Headers})
            .then(res => res.json())
            .then(res => {
                this.setState({tipos: res})
            })
    }

    render() {
        return <Modal
            className="modal-dialog-centered"
            isOpen={this.props.mostrar} toggle={() => this.props.cancelar()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">
                    Nuevo Material
                </h4>
                <button aria-label="Cerrar"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.props.cancelar()}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-tipo">
                                Tipo
                            </label>
                            <CreatableSelect
                                isClearable
                                id="input-tipo"
                                onChange={(e: any) => {
                                    if (e === null || e === undefined || e.value === "") {
                                        this.setState({
                                            material: {
                                                ...this.state.material, tipo: {id: 0, nombre: ""}
                                            }
                                        });
                                        return;
                                    }

                                    this.setState({
                                        material: {
                                            ...this.state.material, tipo: {
                                                id: this.state.tipos.find(x => x.id === e.value) !== undefined ? e.value : 0,
                                                nombre: e.label
                                            }
                                        }
                                    })
                                }}
                                options={Array.from(this.state.tipos.map(prop => ({
                                    value: prop.id,
                                    label: prop.nombre
                                })))}
                                components={makeAnimated()}
                                placeholder="Selecciona o crea uno"
                                className="select-input"
                                classNamePrefix="select-input-prefix"
                                formatCreateLabel={inputValue => "Crear \"" + inputValue + "\""}
                                noOptionsMessage={() => "Cargando..."}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-codigo">
                                Código
                            </label>
                            <Input
                                className="form-control-alternative"
                                id="input-codigo"
                                placeholder="Código del material"
                                type="text"
                                onChange={e => {
                                    e.preventDefault();
                                    this.setState({
                                        material: {...this.state.material, codigo: e.target.value}
                                    })
                                }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="link" data-dismiss="modal" type="button" className="text-success"
                    onClick={() => this.props.cancelar()} disabled={this.state.creando}
                >
                    Cerrar
                </Button>
                <Button
                    className="ml-auto" color="success" type="button"
                    onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
                >
                    {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
                </Button>
            </div>
        </Modal>;
    }
}