import React from 'react';
import preval from 'preval.macro';
import moment from 'moment';
import { Link, NavLink as NavLinkRRD, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';

import { AppRoutes, headerMenu, sideMenu } from '../../const';

import {
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Media,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    NavLink,
    Row,
    UncontrolledDropdown,
    UncontrolledTooltip
} from "reactstrap";
import { NuevoOperativo } from "../../views/operativos";
import { NuevoVoluntario } from "../../views/voluntarios";
import { NuevoMaterial } from "../../views/material";
import { NuevoVehiculo } from "../../views/vehiculos";
import { permiso, Permisos } from "../../const/permisos";
import { Usuario } from "../../const/user";
import { Predefinidas } from "../../views/estadisticas/Predefinidas";
import { GorroNavidad } from "../../comps/gorro";
import { NuevaNoticia } from "../../views/info/noticias";
import { NuevaFormacion } from '../../views/formacion/Nueva';
import { ListadosFormacion } from '../../views/formacion/Listados';
import { FiltroSubstate } from "../../views/operativos/Lista";
import { SearchQuery } from "../../views/operativos/_comps/lista/search";
import { Exclusiones } from '../../views/formacion/Exclusiones';


export const Sidebar: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const [collapse, setCollapse] = React.useState(false);

    const [nuevoOperativo, setNuevoOperativo] = React.useState(false);
    const [nuevoVoluntario, setNuevoVoluntario] = React.useState(false);
    const [nuevoMaterial, setNuevoMaterial] = React.useState(false);
    const [nuevoVehiculo, setNuevoVehiculo] = React.useState(false);
    const [nuevaFormacion, setNuevaFormacion] = React.useState(false);
    const [listadoFormacion, setListadoFormacion] = React.useState(false);
    const [exclusiones, setExclusiones] = React.useState(false);

    const [nuevaNoticia, setNuevaNoticia] = React.useState(false);


    const header = () => {
        return headerMenu.map((prop, key) => {
            return (
                <DropdownItem to={prop.route} tag={Link} key={key} onClick={e => {
                    if (prop.accion) {
                        e.preventDefault();
                        prop.accion();
                        if (location.pathname !== prop.route) {
                            history.push(prop.route)
                        } else {
                            const ahora = "" + location.pathname + location.search;
                            history.push(AppRoutes.Panel);
                            history.push(ahora);
                        }
                    }
                }}>
                    <i><FontAwesomeIcon icon={prop.icon} /></i>
                    <span>{prop.name}</span>
                </DropdownItem>
            );
        });
    };

    const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const operativosPendientesRevision = (admin: boolean = false) => {
        const fs: FiltroSubstate = {
            estado: ["BORRADOR"],
            fechaInicio: null,
            fechaFin: moment.utc(),
            participa: [true, false],
            coordinador: [true],
            emergencia: [true, false],
            externo: [true, false],
            terminos: null,
            tipos: [],
            voluntarios: []
        };
        if (admin) {
            fs.estado = ["REVISION"];
            fs.fechaFin = null;
            fs.coordinador = [true, false];
        }
        const query = SearchQuery.generarFiltro(fs);
        history.push(AppRoutes.Panel);
        sleep(1).then(() => history.push(AppRoutes.Operativos + "?" + query));
    };

    const ESTADISTICAS = React.createRef<Predefinidas>();

    const side = (bottom: boolean = false) => {
        return sideMenu.map((prop, key) => {
            return prop.bottom === bottom ? (
                <NavItem key={key}>
                    {/** El menú principal de avisos solo se muestra si tiene permisos de gestión**/}
                    {(prop.route === "/avisos" && !permiso(Permisos.gestionAvisos)) ? <></> :
                        <>
                        <NavLink
                            to={prop.route}
                            tag={NavLinkRRD}
                            onClick={e => {
                                e.preventDefault();
                                setCollapse(false);
                                if (window.location.pathname.startsWith(prop.route)) history.push("/");
                                sleep(1).then(() => history.push(prop.route));
                            }}
                            activeClassName="active"
                        >
                            <i className={prop.bottom ? "" : ("text-" + prop.color)}><FontAwesomeIcon icon={prop.icon} /></i>
                            {prop.name}
                        </NavLink>
                         {window.location.pathname === prop.route ?
                        (prop.route === AppRoutes.Operativos ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Operativos}
                                    tag={NavLinkRRD}
                                    onClick={e => {
                                        e.preventDefault();
                                        setNuevoOperativo(true)
                                    }}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nuevo operativo
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Estadisticas}
                                    tag={NavLinkRRD}
                                    onClick={e => {
                                        e.preventDefault();
                                        operativosPendientesRevision();
                                    }}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faFlag} /></i> Pendientes
                                </NavLink>
                            </NavItem>
                            {permiso(Permisos.revisarOperativos) ?
                                <NavItem className="nav-subitem">
                                    <NavLink
                                        to={AppRoutes.Estadisticas}
                                        tag={NavLinkRRD}
                                        onClick={e => {
                                            e.preventDefault();
                                            operativosPendientesRevision(true);
                                        }}
                                        className="nav-sublink"
                                    >
                                        <i className="text-gray"><FontAwesomeIcon icon={FA.faEye} /></i> Revisar
                                  </NavLink>
                                </NavItem> : <></>}
                        </> : prop.route === AppRoutes.Formacion && permiso(Permisos.gestionFormacion) ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Formacion}
                                    tag={NavLinkRRD}
                                    onClick={() => setNuevaFormacion(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nueva Formación
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Formacion}
                                    tag={NavLinkRRD}
                                    onClick={() => setListadoFormacion(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Listados
                                </NavLink>
                            </NavItem>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Formacion}
                                    tag={NavLinkRRD}
                                    onClick={() => setExclusiones(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Convalidaciones
                                </NavLink>
                            </NavItem>
                        </> : prop.route === AppRoutes.Material && permiso(Permisos.gestionMaterial) ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Material}
                                    tag={NavLinkRRD}
                                    onClick={() => setNuevoMaterial(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nuevo Material
                                </NavLink>
                            </NavItem>
                        </> : prop.route === AppRoutes.Vehiculos && permiso(Permisos.gestionVehiculos) ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Vehiculos}
                                    tag={NavLinkRRD}
                                    onClick={() => setNuevoVehiculo(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nuevo Vehículo
                                </NavLink>
                            </NavItem>
                        </> : prop.route === AppRoutes.Voluntarios && permiso(Permisos.gestionVoluntarios) ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Voluntarios}
                                    tag={NavLinkRRD}
                                    onClick={() => setNuevoVoluntario(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nuevo Voluntario
                                </NavLink>
                            </NavItem>
                        </> : prop.route === AppRoutes.Estadisticas ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Estadisticas}
                                    tag={NavLinkRRD}
                                    onClick={e => {
                                        e.preventDefault();
                                        ESTADISTICAS.current?.cambiarVisible()
                                    }}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faFilter} /></i> Predefinidas
                                </NavLink>
                            </NavItem>
                        </> : prop.route === AppRoutes.Noticias && permiso(Permisos.gestionNoticias) ? <>
                            <NavItem className="nav-subitem">
                                <NavLink
                                    to={AppRoutes.Noticias}
                                    tag={NavLinkRRD}
                                    onClick={() => setNuevaNoticia(true)}
                                    className="nav-sublink"
                                >
                                    <i className="text-gray"><FontAwesomeIcon icon={FA.faPlus} /></i> Nueva Noticia
                                </NavLink>
                            </NavItem>
                        </> : <></>)
                                : <></>}
                    </>
                    }
                   
                </NavItem>
            ) : (<div key={key} />);
        });
    };

    React.useEffect(() => {
        if (window.location.pathname === "/")
            window.location.pathname.replace('/', AppRoutes.Panel);
    });

    const user = new Usuario().voluntario();
    const buildTime = moment.unix(preval`module.exports = new Date().getTime();` / 1000);

    return (
        <>
            <Navbar
                className="navbar-vertical fixed-left navbar-light bg-white"
                expand="md"
                id="sidenav-main"
            >
                <Container fluid>
                    {/* Toggler */}
                    <button
                        className="navbar-toggler"
                        type="button"
                        onClick={() => setCollapse(!collapse)}
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <img className="d-md-none" alt="..."
                        src={require("../../assets/img/pcivil-brand.png")} style={{ height: 36 }} />
                    {/* Brand */}
                    <NavbarBrand className="pt-0 d-none d-md-inline">
                        <img
                            alt=""
                            className="navbar-brand-img"
                            src={require('../../assets/img/pcivil-sidebar.png')}
                        />
                    </NavbarBrand>
                    {/* User */}
                    <Nav className="align-items-center d-md-none">
                        <UncontrolledDropdown nav>
                            <DropdownToggle nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        <GorroNavidad size="sm" />
                                        <img
                                            alt="..."
                                            src={user?.avatar || ""}
                                        />
                                    </span>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">{user?.apodo !== null ? user?.apodo : (user?.nombre + " " + user?.apellidos)}</h6>
                                </DropdownItem>
                                {header()}
                                <DropdownItem divider />
                                <DropdownItem to={AppRoutes.Exit} tag={Link}>
                                    <i><FontAwesomeIcon icon={FA.faSignOutAlt} /></i>
                                    <span>Cerrar Sesión</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                    {/* Collapse */}
                    <Collapse navbar isOpen={collapse}>
                        {/* Collapse header */}
                        <div className="navbar-collapse-header d-md-none">
                            <Row>
                                <Col className="collapse-brand" xs="6">
                                    <Link to={AppRoutes.Panel}>
                                        <img alt="..." src={require("../../assets/img/pcivil-navbar.png")}
                                            style={{ marginTop: -10 }} />
                                    </Link>
                                </Col>
                                <Col className="collapse-close" xs="6">
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        onClick={() => setCollapse(false)}
                                    >
                                        <span />
                                        <span />
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        {/* Navigation */}
                        <Nav navbar>
                            {side()}
                        </Nav>
                        {/* Divider */}
                        <hr className="my-3" />
                        {/* Heading */}
                        <h6 className="navbar-heading text-muted">Información</h6>
                        {/* Navigation */}
                        <Nav className="mb-md-3" navbar>
                            {side(true)}
                        </Nav>
                        <Nav className="mb-md-3" navbar>
                            <NavItem className="active-pro active" id="version">
                                <NavLink style={{ cursor: "default" }}>
                                    <FontAwesomeIcon
                                        icon={FA.faCode}
                                        style={{ marginRight: "1rem" }}
                                    />
                                    {process.env.REACT_APP_VERSION}
                                </NavLink>
                            </NavItem>
                            <UncontrolledTooltip trigger="hover" placement="top" target="version">
                                {buildTime.toISOString()}
                            </UncontrolledTooltip>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>

            {/* MODALS */}
            <NuevoOperativo
                operativo={nuevoOperativo}
                cancelar={() => {
                    setNuevoOperativo(false);
                }}
                history={history}
                location={location}
                match={match}
            />
            {permiso(Permisos.gestionVoluntarios) ? <NuevoVoluntario
                mostrar={nuevoVoluntario}
                cancelar={() => setNuevoVoluntario(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
            {permiso(Permisos.gestionMaterial) ? <NuevoMaterial
                mostrar={nuevoMaterial}
                cancelar={() => setNuevoMaterial(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
            {permiso(Permisos.gestionVehiculos) ? <NuevoVehiculo
                mostrar={nuevoVehiculo}
                cancelar={() => setNuevoVehiculo(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
            <Predefinidas ref={ESTADISTICAS} />
            {permiso(Permisos.gestionFormacion) ? <NuevaFormacion
                mostrar={nuevaFormacion}
                cancelar={() => setNuevaFormacion(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
            {permiso(Permisos.gestionFormacion) ? <ListadosFormacion
                mostrar={listadoFormacion}
                cancelar={() => setListadoFormacion(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
            {permiso(Permisos.gestionFormacion) ? <Exclusiones
                mostrar={exclusiones}
                cancelar={() => setExclusiones(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}

            {permiso(Permisos.gestionNoticias) ? <NuevaNoticia
                mostrar={nuevaNoticia}
                cancelar={() => setNuevaNoticia(false)}
                history={history}
                location={location}
                match={match}
            /> : <></>}
        </>
    );
};
