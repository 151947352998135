import {Voluntario} from "../models";
import JwtDecode from "jwt-decode";
import ReactGA from 'react-ga';


export class Cookies {
    static setCookie(cname: string, cvalue: string, exhours: number) {
        let d = new Date();
        d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static getCookie(cname: string) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    static delCookie(cname: string) {
        Cookies.setCookie(cname, "", -1);
    }
}


export class Usuario {
    public clave = "pcivil_token";
    private expire = 10;


    token(): any | null {
        return Cookies.getCookie(this.clave);
    }

    voluntario(): Voluntario | null {
        let v: Voluntario | null = null;
        const token = this.token();
        try {
            if (token !== null)
                v = (JwtDecode(token) as any).usr as Voluntario;
        } catch (e) {
            this.eliminar();
        }
        return v;
    }

    eliminar() {
        Cookies.delCookie(this.clave);
    }

    crear(token: string) {
        Cookies.setCookie(this.clave, token, this.expire);
    }
}


interface GuardadoLoginDatos {
    usuario: string;
    clave: string
}

export class GuardadoLogin {
    private claveDatos = "pcivil_login";

    eliminar() {
        Cookies.delCookie(this.claveDatos);
    }

    guardar(usuario: string, clave: string) {
        const datos: GuardadoLoginDatos = {
            usuario: usuario,
            clave: clave
        };
        const datosCodificado = btoa(JSON.stringify(datos));
        Cookies.setCookie(this.claveDatos, datosCodificado, 24 * 365 * 10);

        const user = new Usuario().voluntario();
        if (user !== null)
            ReactGA.set({userId: user.yago});
    }

    recuperar(): GuardadoLoginDatos | null {
        const cookie = Cookies.getCookie(this.claveDatos);
        if (cookie === null)
            return null;

        return JSON.parse(atob(cookie));
    }
}

export class Impersonar {
    private static CLAVE_DATOS = "pcivil_imp";

    static esImpersonar() {
        return sessionStorage.getItem(this.CLAVE_DATOS) !== null;
    }

    static impersonar(token: string) {
        const usuario = new Usuario();
        sessionStorage.setItem(this.CLAVE_DATOS, Cookies.getCookie(usuario.clave) || "");
        usuario.crear(token);
    }

    static depersonar() {
        const usuario = new Usuario();
        usuario.crear(sessionStorage.getItem(this.CLAVE_DATOS) || "");
        sessionStorage.removeItem(this.CLAVE_DATOS);
    }
}
