import React from "react";
import {Helmet} from 'react-helmet';

import {
    Col,
    Card,
    CardBody,
} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';
import {Usuario, GuardadoLogin, Impersonar} from "../../const/user";


export class Salida extends React.Component {
    componentDidMount(): void {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement !== null)
            document.scrollingElement.scrollTop = 0;

        new Usuario().eliminar();
        new GuardadoLogin().eliminar();
        Impersonar.depersonar();
        sessionStorage.removeItem('redirect');
        setTimeout(() => window.location.reload(), 500);
    }

    render() {
        return <>
            <Helmet>
                <title>Acceso | PCivil Santiago</title>
            </Helmet>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <span>Cerrando sesión...</span>
                        </div>
                        <div className="text-center"><FontAwesomeIcon icon={FA.faSpinner} spin size="5x"/></div>
                    </CardBody>
                </Card>
            </Col>
        </>;
    }
}

export default Salida;