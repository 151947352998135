import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import * as views from './views';
import {AppLayout, AuthLayout} from './layout';
import {AppRoutes} from "./const";

//@ts-ignore
import ReactNotification from 'react-notifications-component';

import {Usuario} from "./const/user";
import {Cargando} from "./views";
import withTracker from "./comps/tracker";

require('moment/locale/es');


// @ts-ignore
const AppRoute = ({component: Component, layout: Layout, ...rest}) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )}/>
);

const Redir = () => <Route
    exact path="/r/:c"
    render={props => {
        if (props.match.params.c.includes("@")) window.location.href = "mailto:" + props.match.params.c;
        else window.location.href = "tel:" + props.match.params.c;
        return <></>;
    }}
/>;

const Calendarios = () => <Route
    path="/c/:c"
    render={props => {
        window.location.href = "data:text/calendar;charset=utf8," + props.match.params.c;
        return <></>;
    }}
/>;

let loggedin = new Usuario().voluntario() !== null;

export const App: React.FC = () => {
    if (!loggedin && window.location.pathname !== AppRoutes.Acceso && window.location.pathname !== AppRoutes.Exit) {
        sessionStorage.setItem('redirect', window.location.pathname);
    }

    return <>
        <ReactNotification/>
        <BrowserRouter>
            <Suspense fallback={<AppLayout><Cargando/></AppLayout>}>
                {loggedin ? <Switch>
                    <AppRoute layout={AppLayout} exact path={AppRoutes.Panel} component={withTracker(views.Panel)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Operativos} component={withTracker(views.Operativos)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Material} component={withTracker(views.Material)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Vehiculos} component={withTracker(views.Vehiculos)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Voluntarios} component={withTracker(views.Voluntarios)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Estadisticas} component={withTracker(views.Estadisticas)} />
                    <AppRoute layout={AppLayout} path={AppRoutes.Formacion} component={withTracker(views.Formacion)} />

                    <AppRoute layout={AppLayout} path={AppRoutes.Alertas} component={withTracker(views.Alertas)} />
                    <AppRoute layout={AppLayout} path={AppRoutes.Avisos} component={withTracker(views.Avisos)} />
                    <AppRoute layout={AppLayout} path={AppRoutes.Nido} component={withTracker(views.Nido)}/>
                    <AppRoute layout={AppLayout} path={AppRoutes.Noticias} component={withTracker(views.Noticias)}/>
                    <AppRoute layout={AppLayout} exact path={AppRoutes.Documentacion} component={withTracker(views.Recursos)}/>

                    <AppRoute layout={AuthLayout} exact path={AppRoutes.Exit} component={withTracker(views.Salida)}/>
                    {Redir()}
                    {Calendarios()}
                    <Route path="*"><Redirect to={AppRoutes.Panel}/></Route>
                </Switch> : <Switch>
                    <AppRoute layout={AuthLayout} path={AppRoutes.Acceso} component={withTracker(views.Acceso)}/>
                    {Redir()}
                    {Calendarios()}
                    <Route path="*"><Redirect to={AppRoutes.Acceso}/></Route>
                </Switch>}
            </Suspense>
        </BrowserRouter>
    </>;
};

export default Sentry.withProfiler(App);