import React from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Skeleton from "react-loading-skeleton";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledPopover,
  UncontrolledTooltip
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import NumberFormat from "react-number-format";

import {AppRoutes, n} from "../const";
import {BaseURL, Error as E, Headers} from "../http";
import { Emergencia, OperativoLista, OperativosLlenos, Voluntario } from "../models";
import { CursosListado } from "../models/Formacion";
import {EstadisticasAgrupacion} from "../models/Estadisticas";
import {Notas} from "./operativos/_comps/Notas";

interface PanelProps {
}

interface PanelState {
  operativos: {
    pendientes: OperativoLista[];
    total: number;
    apuntando: string | null;
  };
  formacion: {
    pendientes: CursosListado[];
    total: number;
    apuntado: string | null;
  }
  avisos: {
    ultimas: Emergencia[];
    total: number;
  };
  estadisticas: EstadisticasAgrupacion | null;
  mostrarEstadisticas: boolean;
  operativoNotas: OperativoLista | null;
  nido: Voluntario[] | null;
}

export class Panel extends React.Component<PanelProps, PanelState> {
  constructor(props: PanelProps) {
    super(props);
    this.state = {
      operativos: {
        pendientes: [],
        total: -1,
        apuntando: null
      },
      formacion: {
        pendientes: [],
        total: -1,
        apuntado: null,
      },
      avisos: {
        ultimas: [],
        total: -1
      },
      estadisticas: null,
      mostrarEstadisticas: false,
      operativoNotas: null,
      nido: null
    }
  }

  private apuntarse(operativo: OperativoLista): void {
    if (operativo.participa || this.state.operativos.apuntando !== null) {
      return;
    } else if (operativo.inscripciones) {
      n("Inscripciones cerradas", OperativosLlenos[operativo.inscripciones], "warning");
      return;
    }
    this.setState({operativos: {...this.state.operativos, apuntando: operativo.uuid}});
    fetch(BaseURL + "/operativos/" + operativo.uuid + "/voluntarios/alta", {
      headers: Headers, method: "PUT"
    })
      .then(() => {
        this.setState({operativos: {...this.state.operativos, apuntando: null}}, () => {
          n("Listo", "Te has dado de alta en el operativo", "success");
          let ops = [...this.state.operativos.pendientes];
          let opId = this.state.operativos.pendientes.findIndex(op => op === operativo);
          operativo.participa = !operativo.participa;
          ops[opId] = operativo;
          this.setState({operativos: {...this.state.operativos, pendientes: ops}}, () => {
            if (operativo.participa && operativo.notas) {
              this.setState({operativoNotas: operativo});
            }
          });
        });
      })
      .catch(error => E(error));
    }

    private apuntarseCurso = (curso: CursosListado) => {

        if (curso.apuntado === "NO_CUMPLE_REQUISITOS" || curso.apuntado === "CURSO_CERRADO" || curso.apuntado === "CURSO_SIN_ABRIR"
            || curso.apuntado === "LIMITE_COMPLETADO") {
            return;
        }
        fetch(BaseURL + "/cursos/edicion/" + curso.edicion.uuid + "/voluntarios/" + (curso.apuntado === 'PARTICIPA' ? "baja" : "alta"), {
            headers: Headers,
            method: (curso.apuntado === 'PARTICIPA' ? "DELETE" : "PUT")
        })
        .then(() => {
            if (curso.apuntado === 'PARTICIPA') {
                n("Listo", "Se te ha dado de baja en el curso", "success");
                curso.apuntado = "NO_PARTICIPA";
            } else {
                n("Listo", "Te has dado de alta en el curso", "success");
                curso.apuntado = "PARTICIPA";
            }

            let ediciones = [...this.state.formacion.pendientes];
            let index = this.state.formacion.pendientes.findIndex(ed => ed === curso);
            ediciones[index] = curso;
            this.setState({ formacion: { ...this.state.formacion, pendientes: ediciones } });
        })
        .catch(error => Error(error));
    }


  componentDidMount(): void {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement !== null)
      document.scrollingElement.scrollTop = 0;

    // CABECERA

    fetch(BaseURL + "/estadisticas/panel", {headers: Headers})
      .then(res => res.json())
      .then((res: EstadisticasAgrupacion) => this.setState({estadisticas: res}))
      .catch(error => E(error));

    // TABLAS

    fetch(BaseURL + "/operativos/activos?orden=horaNido", {headers: Headers})
      .then(res => res.json())
      .then(json => {
        json.map((prop: any) => {
          return prop.horaNido = moment.utc(prop.horaNido);
        });
        this.setState({operativos: {...this.state.operativos, pendientes: json, total: json.length}});
      })
          .catch(error => E(error));

     fetch(BaseURL + "/cursos/edicion/activos?orden=horaInicio", { headers: Headers })
        .then(res => res.json())
        .then(json => {
            json.map((prop: any) => {
                return prop.edicion.horaInicio = moment.utc(prop.edicion.horaInicio);
            });
            this.setState({ formacion: { ...this.state.formacion, pendientes: json, total: json.length } });
        })
        .catch(error => E(error));


    fetch(BaseURL + "/alertas?size=5&order=hora", {headers: Headers})
      .then(res => res.json())
      .then((json) => {
        json.map((prop: any) => {
          return prop.fecha = moment.utc(prop.fecha);
        });
        this.setState({avisos: {...this.state.avisos, ultimas: json, total: json.length}});
      })
      .catch(error => E(error));

    fetch(BaseURL + "/nido", {headers: Headers})
      .then(res => res.json())
      .then((res: Voluntario[]) => this.setState({nido: res}))
      .catch(error => E(error));
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Panel de Control</title>
        </Helmet>
       {/*<div className="snowflakes" aria-hidden="true">
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❅</div>
        </div>*/}
        <div className="header bg-gradient-orange pb-6 pb-lg-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row className="d-lg-none d-xl-none">
                <Col>
                  <Button
                    type="button" color="secondary" className="mb-4" style={{width: "100%"}}
                    onClick={() => this.setState({
                      mostrarEstadisticas: !this.state.mostrarEstadisticas
                    })}
                  >
                    <FontAwesomeIcon icon={FA.faChartBar}/>
                    {" "}{this.state.mostrarEstadisticas ? "Ocultar" : "Mostrar"} Estadísticas
                  </Button>
                </Col>
              </Row>
              <Row className={(this.state.mostrarEstadisticas ? "d-flex" : "d-none") + " d-lg-flex"}>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0" tag={Link} to={AppRoutes.Voluntarios}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Voluntarios
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.estadisticas !== null ? <NumberFormat
                              value={this.state.estadisticas.numVoluntarios}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                            /> : <Skeleton/>}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div
                            className="icon icon-shape bg-teal text-white rounded-circle shadow">
                            <FontAwesomeIcon icon={FA.faUserShield}/>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-nowrap">Voluntarios en activo</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0" tag={Link} to={AppRoutes.Operativos}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Operativos este año
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.estadisticas !== null ? <NumberFormat
                              value={this.state.estadisticas.numOperativos}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={1}
                            /> : <Skeleton/>}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div
                            className="icon icon-shape bg-orange text-white rounded-circle shadow">
                            <FontAwesomeIcon icon={FA.faCompass}/>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.estadisticas !== null ?
                          (this.state.estadisticas.numOperativosAntes != null ? <>
                            <span
                              className={"mr-2 " + (this.state.estadisticas.numOperativos > this.state.estadisticas.numOperativosAntes ? "text-success" :
                                this.state.estadisticas.numOperativos < this.state.estadisticas.numOperativosAntes ? "text-danger" : "text-gray")}
                            >
                                <FontAwesomeIcon
                                  icon={this.state.estadisticas.numOperativos > this.state.estadisticas.numOperativosAntes ? FA.faArrowUp :
                                    this.state.estadisticas.numOperativos < this.state.estadisticas.numOperativosAntes ? FA.faArrowDown : FA.faArrowsAltV}
                                />
                              {" "}
                              <NumberFormat
                                value={this.state.estadisticas.numOperativos / this.state.estadisticas.numOperativosAntes * 100}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                decimalScale={2}
                              />%
                            </span>
                            <span className="text-nowrap">vs. Año Anterior</span>
                            </> : <span className="text-nowrap">Operativos este año</span>) :
                          <Skeleton width={50}/>}
                      </p>
                    </CardBody>
                  </Card> 
                 </Col>

                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0" tag={Link} to={AppRoutes.Formacion}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Formación
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.estadisticas !== null ? <NumberFormat
                              value={this.state.estadisticas.numCursosVoluntario}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              decimalSeparator={','}
                              decimalScale={1}
                            /> : <Skeleton/>}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div
                            className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <FontAwesomeIcon icon={FA.faBookOpen}/>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.estadisticas !== null ? <>
                            <span
                             className={"mr-2 " + (this.state.estadisticas.numCursosObligatorios >= this.state.estadisticas.numCursosBasicos
                                    ? "text-success" : "text-danger")}
                            >
                            <FontAwesomeIcon
                                icon={this.state.estadisticas.numCursosObligatorios >= this.state.estadisticas.numCursosBasicos
                                    ? FA.faCheck : FA.faTimes} />
                                {" "}{this.state.estadisticas.numCursosObligatorios} / {this.state.estadisticas.numCursosBasicos} cursos obligatorios
                            </span>
                        </> : <Skeleton width={50} />}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0" tag={Link}
                        to={AppRoutes.Operativos + "?estado=FINALIZADO&fechaInicio="
                        + moment().startOf("quarter").unix() + "&fechaFin="
                        + moment().endOf("quarter").unix() + "&participa=1&emergencia=1,0" +
                        "&externo=1,0&orden=horaNido&desc=1&page=0&size=20"}>
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                            Trimestralidad
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.estadisticas !== null ?
                                (this.state.estadisticas.horasVoluntarioTrimestre < 30 ? <><NumberFormat
                                    value={this.state.estadisticas.horasVoluntarioTrimestre}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={0}
                                />h</> : "+30h")
                                : <Skeleton />}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div
                            className="icon icon-shape bg-indigo text-white rounded-circle shadow">
                            <FontAwesomeIcon icon={FA.faHandshake}/>
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.estadisticas !== null ? <>
                        <span
                          className={"mr-2 " + (this.state.estadisticas.horasVoluntarioTrimestre >= 30
                            ? "text-success" : "text-danger")}
                        >
                            <FontAwesomeIcon
                              icon={this.state.estadisticas.horasVoluntarioTrimestre >= 30
                              ? FA.faCheck : FA.faTimes}/>
                          {" "}30 horas
                        </span>
                        </> : <Skeleton width={50}/>}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Operativos pendientes</h3>
                    </div>
                    <div className="col text-right">
                      <Button color="warning" to={AppRoutes.Operativos} tag={Link} size="sm">
                        Ver todos
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col" className="d-lg-none">Operativo</th>
                    <th scope="col"/>
                    <th scope="col" className="d-none d-lg-table-cell">Nombre</th>
                    <th scope="col" className="d-none d-lg-table-cell">Fecha</th>
                    <th scope="col" className="d-none d-lg-table-cell">Hora nido</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.operativos.total > -1 &&
                  this.state.operativos.pendientes.find(prop => prop.participa || prop.inscripciones === 0) !== undefined ?
                    this.state.operativos.pendientes
                      .filter(prop => prop.participa || prop.inscripciones === 0)
                      .map((prop, key) => <React.Fragment key={key}>
                        <tr>
                          <td>
                            {prop.participa ? (
                              <Button
                                onClick={e => e.preventDefault()}
                                color="success" size="sm" type="button" outline disabled
                              >
                                <FontAwesomeIcon icon={FA.faThumbsUp}/>
                              </Button>
                            ) : (
                              <>
                                <Button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.apuntarse(prop);
                                  }}
                                  color="primary" size="sm" type="button"
                                  data-placement="top" id={"op" + prop.uuid}
                                  disabled={this.state.operativos.apuntando !== null}
                                  outline={prop.inscripciones !== 0}
                                >
                                  {this.state.operativos.apuntando === prop.uuid ? (
                                    <FontAwesomeIcon icon={FA.faSpinner} spin/>
                                  ) : <FontAwesomeIcon
                                    icon={prop.inscripciones === 0 ? FA.faThumbsDown : FA.faLock}/>}
                                </Button>
                                <UncontrolledTooltip delay={0} placement="top"
                                                     target={"op" + prop.uuid}>
                                  {prop.totalVoluntarios + " Voluntario" + (prop.totalVoluntarios === 1 ? "" : "s")}
                                </UncontrolledTooltip>
                              </>
                            )}
                          </td>
                          <th scope="row">
                            <Button
                              tag={Link} to={AppRoutes.Operativos + "/" + prop.uuid}
                              size="sm" type="button" color="secondary" outline
                            >
                              <span className="mb-0 text-sm">
                                {prop.inscripciones === 0 && prop.totalVoluntarios < 5 ?
                                  <span
                                    className={prop.participa ? "text-success" : "text-danger"}>
                                        <FontAwesomeIcon
                                          icon={FA.faExclamationCircle}/>{" "}
                                    </span> : <></>}
                                {prop.nombre}
                              </span>
                            </Button>
                          </th>
                          <td className="d-none d-lg-table-cell">{prop.horaNido.local().format("DD/MM/YYYY")}</td>
                          <td className="d-none d-lg-table-cell">{prop.horaNido.local().format("HH:mm")}</td>
                        </tr>
                        <tr className="d-lg-none">
                          <td style={{borderTopColor: "transparent", paddingTop: 0}}>
                            {prop.horaNido.local().fromNow()}
                          </td>
                          <th scope="row"
                              style={{borderTopColor: "transparent", paddingTop: 0}}>
                            {prop.horaNido.local().format("DD/MM/YYYY - HH:mm")}
                          </th>
                        </tr>
                      </React.Fragment>) : (this.state.operativos.total > -1 ? (<tr>
                      <th scope="row">No hay operativos pendientes</th>
                    </tr>) : (
                      <>{[1, 2, 3].map((i) => {
                        return (
                          <tr key={i.toString()}>
                            {[1, 2, 3, 4].map((i) => {
                              return <td
                                key={i.toString()}
                                className={i > 2 ? "d-none d-lg-table-cell" : ""}
                              >
                                <Skeleton/>
                              </td>;
                            })}
                          </tr>
                        );
                      })}</>
                    ))}
                  </tbody>
                </Table>
              </Card>

              <br></br>
              <Card className="shadow">
                    <CardHeader className="border-0">
                        <Row className="align-items-center">
                            <div className="col">
                                <h3 className="mb-0">Formación pendiente</h3>
                            </div>
                            <div className="col text-right">
                                <Button color="warning" to={AppRoutes.Formacion} tag={Link} size="sm">
                                    Ver todos
                        </Button>
                            </div>
                        </Row>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" className="d-lg-none">Curso</th>
                                <th scope="col" />
                                <th scope="col" className="d-none d-lg-table-cell">Nombre</th>
                                <th scope="col" className="d-none d-lg-table-cell">Fecha</th>
                                <th scope="col" className="d-none d-lg-table-cell">Hora nido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.formacion.total > -1 &&
                                this.state.formacion.pendientes.find(prop => prop.apuntado) !== undefined ?
                                this.state.formacion.pendientes
                                    .filter(prop => prop.apuntado)
                                    .map((prop, key) => <React.Fragment key={key}>
                                        <tr>
                                            <td>
                                                <Button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.apuntarseCurso(prop);
                                                    }}
                                                    size="sm" type="button" data-placement="top" id={"edicionPanel" + prop.edicion.uuid}
                                                    color={
                                                        prop.apuntado === "PARTICIPA" ? (
                                                            prop.edicion.horaInicio.isAfter(moment.utc()) ? "default" : "success"
                                                        ) : (
                                                            prop.edicion.horaInicio.isAfter(moment.utc()) ? "primary" : "danger"
                                                        )
                                                    }
                                                    disabled={moment.utc().isAfter(prop.edicion.horaInicio)}
                                                    outline={moment.utc().isAfter(prop.edicion.horaInicio)}
                                                >

                                                    <FontAwesomeIcon icon={prop.apuntado === "PARTICIPA" ? FA.faThumbsUp :
                                                        (prop.apuntado === "NO_PARTICIPA" ? FA.faThumbsDown : FA.faLock)} />

                                                </Button>
                                                <UncontrolledTooltip delay={0} placement="top" target={"edicionPanel" + prop.edicion.uuid}>
                                                    {
                                                        prop.apuntado === "PARTICIPA" ?
                                                            prop.edicion.horaInicio > moment.utc() ? "Apuntado" : "Participado"
                                                            : prop.apuntado === "NO_PARTICIPA" ?
                                                                prop.edicion.horaInicio > moment.utc() ? "No apuntado" : "No participado"
                                                                : prop.apuntado === "NO_CUMPLE_REQUISITOS" ? "No cumple los requisitos para apuntarse"
                                                                    : prop.apuntado === "CURSO_CERRADO" ? "El curso ya está cerrado a nuevas inscripciones"
                                                                        : prop.apuntado === "CURSO_SIN_ABRIR" ? "El curso aun no ha abierto a nuevas inscripciones"
                                                                            : prop.apuntado === "CURSO_BLOQUEADO" ? "El curso está temporalmente bloqueado por los responsables de formación"
                                                                                : "El curso ya tienen completo el límite de voluntarios"

                                                    }
                                                </UncontrolledTooltip>
                                            </td>
                                            <th scope="row">
                                                <Button
                                                    tag={Link} to={AppRoutes.Formacion + "/" + prop.edicion.uuid}
                                                    size="sm" type="button" color="secondary" outline
                                                >
                                                    <span className="mb-0 text-sm">
                                                        {prop.edicion.curso.nombre + (prop.edicion.curso.tipo?.id === 1 ? " - " + prop.edicion.edicion + " edicion" : "")}
                                                    </span>
                                                </Button>
                                            </th>
                                            <td className="d-none d-lg-table-cell">{prop.edicion.horaInicio.local().format("DD/MM/YYYY")}</td>
                                            <td className="d-none d-lg-table-cell">{prop.edicion.horaInicio.local().format("HH:mm")}</td>
                                        </tr>
                                        <tr className="d-lg-none">
                                            <td style={{ borderTopColor: "transparent", paddingTop: 0 }}>
                                                {prop.edicion.horaInicio.local().fromNow()}
                                            </td>
                                            <th scope="row"
                                                style={{ borderTopColor: "transparent", paddingTop: 0 }}>
                                                {prop.edicion.horaInicio.local().format("DD/MM/YYYY - HH:mm")}
                                            </th>
                                        </tr>
                                    </React.Fragment>) : (this.state.operativos.total > -1 ? (<tr>
                                        <th scope="row">No hay formación pendiente</th>
                                    </tr>) : (
                                        <>{[1, 2, 3].map((i) => {
                                            return (
                                                <tr key={i.toString()}>
                                                    {[1, 2, 3, 4].map((i) => {
                                                        return <td
                                                            key={i.toString()}
                                                            className={i > 2 ? "d-none d-lg-table-cell" : ""}
                                                        >
                                                            <Skeleton />
                                                        </td>;
                                                    })}
                                                </tr>
                                            );
                                        })}</>
                                    ))}
                        </tbody>
                    </Table>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Últimas llamadas 112</h3>
                    </div>
                    <div className="col text-right">
                      <Button color="warning" to={AppRoutes.Alertas} size="sm" tag={Link} type="button">
                        Ver todas
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col"/>
                    <th scope="col">Fecha</th>
                  </tr>
                  </thead>

                  <tbody>
                  {
                    this.state.avisos.total > 0 ? this.state.avisos.ultimas.map((prop, key) => {
                      return <tr key={key.toString()}>
                        <th scope="row">
                          <Button
                            onClick={e => e.preventDefault()}
                            color="primary" size="sm" type="button"
                            data-placement="top" id={"al" + key}
                          >
                            {prop.codigoTipo}
                          </Button>
                          <UncontrolledPopover trigger="hover" placement="top"
                                               target={"al" + key}>
                            <PopoverHeader>{prop.descripcionTipo}</PopoverHeader>
                            <PopoverBody>
                              {prop.descripcion}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </th>
                        <td>{prop.fecha.local().fromNow()}</td>
                      </tr>;
                    }) : this.state.avisos.total > -1 ? <tr>
                      <th scope="row">No hay alertas recientes</th>
                    </tr> : <>{[1, 2, 3, 4].map((i) => {
                      return (
                        <tr key={i.toString()}>
                          {[1, 2].map((i) => {
                            return <td key={i.toString()}><Skeleton/></td>;
                          })}
                        </tr>
                      );
                    })}</>
                  }
                  </tbody>
                </Table>
              </Card>
              <Card className="shadow mt-3">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">En nido</h3>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th scope="col"/>
                    <th scope="col">Voluntario</th>
                  </tr></thead>

                  <tbody>
                  {
                    this.state.nido !== null && this.state.nido.length > 0 ? this.state.nido.map((prop, key) => {
                      return <tr key={key.toString()}>
                        <td>
                          <Link
                            className="avatar avatar-sm"
                            onClick={(e) => e.preventDefault()}
                            to={AppRoutes.Voluntarios + "/" + prop.uuid}
                            id={"nido" + key}
                          >
                            <img alt="..." className="rounded-circle" src={prop.avatar}/>
                          </Link>
                          <UncontrolledPopover trigger="hover" placement="top" target={"nido" + key}>
                            <PopoverHeader>{prop.yago}</PopoverHeader>
                            <PopoverBody>
                              {prop.nombre + " " + prop.apellidos}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </td>
                        <th scope="row">
                          <Button
                            color="secondary" outline size="sm" type="button" tag={Link}
                            to={AppRoutes.Voluntarios + "/" + prop.uuid}
                          >
                            {prop.yago + " " + (prop.apodo !== null && prop.apodo.length > 0 ? prop.apodo : prop.nombre)}
                          </Button>
                        </th>
                      </tr>;
                    }) : this.state.nido !== null ? <tr>
                      <td/>
                      <th scope="row">No hay voluntarios en nido</th>
                    </tr> : <>{[1, 2, 3, 4].map((i) => {
                      return (
                        <tr key={i.toString()}>
                          {[1].map((i) => {
                            return <td key={i.toString()}><Skeleton/></td>;
                          })}
                        </tr>
                      );
                    })}</>
                  }
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
        <Notas operativo={this.state.operativoNotas} cerrar={() => this.setState({operativoNotas: null})}/>
      </>
    );
  }
}

export default Panel;