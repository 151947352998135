import React from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as FA from '@fortawesome/free-solid-svg-icons';

import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Navbar as RNavbar,
    Nav,
    Container
} from "reactstrap";
import {AppRoutes, headerMenu} from "../../const";
import {Impersonar, Usuario} from "../../const/user";
import {GorroNavidad} from "../../comps/gorro";


export const Navbar: React.FC = () => {
    const user = new Usuario().voluntario();
    let history = useHistory();
    let location = useLocation();

    const header = () => {
        return headerMenu.map((prop, key) => {
            return (
                <DropdownItem to={prop.route} tag={Link} key={key.toString()} onClick={e => {
                    if (prop.accion) {
                        e.preventDefault();
                        prop.accion();
                        if (location.pathname !== prop.route) {
                            history.push(prop.route)
                        } else {
                            const ahora = "" + location.pathname + location.search;
                            history.push(AppRoutes.Panel);
                            history.push(ahora);
                        }
                    }
                }}>
                    <i><FontAwesomeIcon icon={prop.icon}/></i>
                    <span>{prop.name}</span>
                </DropdownItem>
            );
        });
    };

    return (
        <>
            <RNavbar className="navbar-top navbar-dark d-none d-md-flex" expand="md" id="navbar-main">
                <Container fluid>
                    <Link className="d-none d-md-inline-block" to={AppRoutes.Panel}>
                        <img alt="..." src={require("../../assets/img/pcivil-brand.png")} style={{height: 36, filter: "brightness(0) invert(1)"}}/>
                    </Link>
                    <div/>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        <GorroNavidad size="sm"/>
                                        <img
                                            alt="..."
                                            src={user !== null ? user.avatar : ""}
                                        />
                                    </span>
                                    <Media className="ml-2 d-none d-md-block">
                                        <span className="mb-0 text-sm font-weight-bold">
                                            {user !== null ? (user.apodo !== null && user.apodo !== "" ? user.apodo : user.nombre) : ""}
                                        </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">{user !== null ? (user.nombre + " " + user.apellidos) : null}</h6>
                                </DropdownItem>
                                {header()}
                                <DropdownItem divider/>
                                {Impersonar.esImpersonar() ? <DropdownItem style={{cursor: "pointer"}} onClick={e => {
                                    e.preventDefault();
                                    Impersonar.depersonar();
                                    window.location.reload();
                                }}>
                                    <i><FontAwesomeIcon icon={FA.faUserSecret}/></i>
                                    <span>Volver</span>
                                </DropdownItem> : <></>}
                                <DropdownItem to={AppRoutes.Exit} tag={Link}>
                                    <i><FontAwesomeIcon icon={FA.faSignOutAlt}/></i>
                                    <span>Cerrar Sesión</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </RNavbar>
        </>
    );
};
