import {FiltroSubstate, OrdenSubtate} from "../../Lista";
import moment, {Moment} from "moment";
import {permiso, Permisos} from "../../../../const/permisos";

export class SearchQuery {
  private static FILTRO_ESTADO = "estado";
  private static FILTRO_FECHAINICIO = "fechaInicio";
  private static FILTRO_FECHAFIN = "fechaFin";
  private static FILTRO_PARTICIPA = "participa";
  private static FILTRO_COORDINADOR = "coordina";
  private static FILTRO_EMERGENCIA = "emergencia";
  private static FILTRO_EXTERNO = "externo";
  private static FILTRO_TERMINOS = "terminos";
  private static FILTRO_TIPOS = "tipos";
  private static FILTRO_VOLUNTARIOS = "voluntarios";

  private static ORDEN_CLAVE = "orden";
  private static ORDEN_DESC = "desc";

  private static PAGE_PAGINA = "page";
  private static PAGE_MUESTRA = "size";


  /* --- */


  private static validar(dato: string, opciones: string[], uno: boolean) {
    const datos = dato.split(",");
    if (uno && datos.length > 1) {
      return false;
    } else if (uno) {
      return opciones.indexOf(dato) > -1;
    }
    let seguro = true;
    datos.forEach(d => {
      if (opciones.indexOf(d) === -1) {
        seguro = false;
        return;
      }
    });
    return seguro;
  }

  private static validarNumero(dato: string): boolean {
    return !isNaN(parseInt(dato));
  }

  public static esValido(s: string): boolean {
    if (s === "" || s === "?" || !s.startsWith("?")) {
      return false;
    }
    s = s.substr(1);

    let seguro = true;
    s.split("&").forEach(params => {
      let datos = params.split("=");
      if (datos.length !== 2) {
        seguro = false;
        return;
      }
      let clave = datos[0];
      let valor = datos[1];

      switch (clave) {
        case this.FILTRO_ESTADO:
        case this.FILTRO_PARTICIPA:
        case this.FILTRO_COORDINADOR:
        case this.FILTRO_EMERGENCIA:
        case this.FILTRO_EXTERNO:
        case this.ORDEN_CLAVE:
        case this.ORDEN_DESC:
          let opciones = ['0', '1'];
          let uno = false;
          if (clave === this.FILTRO_ESTADO) {
            opciones = ["BORRADOR", "REVISION", "FINALIZADO"];
          } else if (clave === this.ORDEN_CLAVE) {
            uno = true;
            opciones = ["nombre", "horaNido"];
          } else if (clave === this.ORDEN_DESC) {
            uno = true;
          }
          if (!this.validar(valor, opciones, uno)) {
            seguro = false;
            return false;
          }
          break;
        case this.FILTRO_FECHAINICIO:
        case this.FILTRO_FECHAFIN:
        case this.PAGE_PAGINA:
        case this.PAGE_MUESTRA:
          if (!this.validarNumero(valor)) {
            seguro = false;
            return false;
          }
          break;
        case this.FILTRO_TERMINOS:
          break;
        case this.FILTRO_TIPOS:
        case this.FILTRO_VOLUNTARIOS:
          clave.split(",").forEach(c => {
            if (isNaN(parseInt(c))) {
              seguro = false;
              return false;
            }
          });
          break;
        default:
          seguro = false;
          return false;
      }
    });

    return seguro;
  }

  /* --- */

  public static generarFiltro(filtro: FiltroSubstate): string {
    let filtros: string[] = [];
    if (filtro.estado.length > 0)
      filtros.push(this.FILTRO_ESTADO + "=" + filtro.estado.map(prop => prop).join(","));
    if (filtro.fechaInicio !== null)
      filtros.push(this.FILTRO_FECHAINICIO + "=" + filtro.fechaInicio.unix());
    if (filtro.fechaFin !== null)
      filtros.push(this.FILTRO_FECHAFIN + "=" + filtro.fechaFin.unix());
    if (filtro.participa.length > 0)
      filtros.push(this.FILTRO_PARTICIPA + "=" + filtro.participa.map(prop => prop ? 1 : 0).join(","));
    if (filtro.coordinador.length > 0)
      filtros.push(this.FILTRO_COORDINADOR + "=" + filtro.coordinador.map(prop => prop ? 1 : 0).join(","));
    if (filtro.emergencia.length > 0)
      filtros.push(this.FILTRO_EMERGENCIA + "=" + filtro.emergencia.map(prop => prop ? 1 : 0).join(","));
    if (filtro.externo.length > 0)
      filtros.push(this.FILTRO_EXTERNO + "=" + filtro.externo.map(prop => prop ? 1 : 0).join(","));
    if (filtro.tipos.length > 0)
      filtros.push(this.FILTRO_TIPOS + "=" + filtro.tipos.join(","));
    if (permiso(Permisos.gestionOperativos) && filtro.voluntarios.length > 0)
      filtros.push(this.FILTRO_VOLUNTARIOS + "=" + filtro.voluntarios.join(","));
    if (filtro.terminos !== null)
      filtros.push(this.FILTRO_TERMINOS + "=" + encodeURIComponent(filtro.terminos).replace(/%20/g, "+"));
    return filtros.join("&");
  }

  public static generarOrden(orden: OrdenSubtate | null): string {
    if (orden === null)
      return "";
    return this.ORDEN_CLAVE + "=" + orden.clave + "&" + this.ORDEN_DESC + "=" + (orden.desc ? 1 : 0);
  }

  public static generarPaginacion(pagina: number, muestra: number): string {
    return this.PAGE_PAGINA + "=" + (pagina - 1) + "&" + this.PAGE_MUESTRA + "=" + muestra;
  }

  public static generar(filtro: FiltroSubstate, orden: OrdenSubtate | null, pagina: number, muestra: number): string {
    const args: string[] = [
      this.generarFiltro(filtro),
      this.generarOrden(orden),
      this.generarPaginacion(pagina, muestra)
    ];
    let query = args.filter(s => s !== "").join("&");
    return (query !== "" ? "?" : "") + query;
  }

  /* --- */

  public static parsearFiltro(s: string, o: FiltroSubstate): FiltroSubstate {
    if (s === "" || s === "?" || !s.startsWith("?")) {
      return o;
    }
    s = s.substr(1);

    let estado: string[] | null = null;
    let fechaInicio: Moment | null = null;
    let fechaFin: Moment | null = null;
    let participa: boolean[] | null = null;
    let coordinador: boolean[] | null = null;
    let emergencia: boolean[] | null = null;
    let externo: boolean[] | null = null;
    let terminos: string | null = null;
    let tipos: string[] | null = null;
    let voluntarios: string[] | null = null;

    s.split("&").forEach(params => {
      let datos = params.split("=");
      switch (datos[0]) {
        case this.FILTRO_ESTADO:
          estado = [];
          datos[1].split(",").forEach(prop => {
            if (estado !== null && ["BORRADOR", "REVISION", "FINALIZADO"].indexOf(prop) > -1) {
              estado.push(prop);
            } else {
              estado = null;
            }
          });
          break;
        case this.FILTRO_FECHAINICIO:
        case this.FILTRO_FECHAFIN:
          let momento: Moment | null = null;
          let numero = parseInt(datos[1]);
          if (!isNaN(numero)) {
            momento = moment.unix(numero);
            if (!momento.isValid()) {
              momento = null
            }
          }

          if (datos[0] === this.FILTRO_FECHAINICIO) {
            fechaInicio = momento;
          } else if (datos[0] === this.FILTRO_FECHAFIN) {
            fechaFin = momento;
          }
          break;
        case this.FILTRO_PARTICIPA:
        case this.FILTRO_COORDINADOR:
        case this.FILTRO_EMERGENCIA:
        case this.FILTRO_EXTERNO:
          let valores: boolean[] | null = [];
          datos[1].split(",").forEach(prop => {
            if (valores !== null && ["1", "0"].indexOf(prop) > -1) {
              valores.push(prop === "1");
            } else {
              valores = null;
            }
          });

          if (datos[0] === this.FILTRO_PARTICIPA) {
            participa = valores;
          } else if (datos[0] === this.FILTRO_COORDINADOR) {
            coordinador = valores;
          } else if (datos[0] === this.FILTRO_EMERGENCIA) {
            emergencia = valores;
          } else if (datos[0] === this.FILTRO_EXTERNO) {
            externo = valores;
          }
          break;
        case this.FILTRO_TERMINOS:
          terminos = decodeURIComponent(datos[1].replace(/\+/g, "%20"));
          break;
        case this.FILTRO_VOLUNTARIOS:
          if (permiso(Permisos.gestionOperativos))
            voluntarios = datos[1].split(",");
          break;
        case this.FILTRO_TIPOS:
          tipos = datos[1].split(",");
          break;
        default:
          break;
      }
    });

    return {
      estado: estado || o.estado,
      fechaInicio: fechaInicio || o.fechaInicio,
      fechaFin: fechaFin || o.fechaFin,
      participa: participa || o.participa,
      coordinador: coordinador || o.coordinador,
      emergencia: emergencia || o.emergencia,
      externo: externo || o.externo,
      terminos: terminos || o.terminos,
      tipos: tipos || o.tipos,
      voluntarios: voluntarios || o.voluntarios
    };
  }

  public static parsearOrden(s: string, o: OrdenSubtate | null): OrdenSubtate | null {
    if (s === "" || s === "?" || !s.startsWith("?")) {
      return o;
    }
    s = s.substr(1);

    let clave: string | null = null;
    let desc: boolean | null = null;
    s.split("&").forEach(params => {
      let datos = params.split("=");
      switch (datos[0]) {
        case this.ORDEN_CLAVE:
          if (["nombre", "horaNido"].indexOf(datos[1]) > -1) {
            clave = datos[1];
          }
          break;
        case this.ORDEN_DESC:
          if (["1", "0"].indexOf(datos[1]) > -1) {
            desc = datos[1] === "1";
          }
          break;
        default:
          break;
      }
    });

    let orden: OrdenSubtate | null;
    if ((clave !== null || desc !== null) && o !== null) {
      orden = {
        clave: clave || o.clave,
        desc: desc || o.desc
      };
    } else {
      orden = o;
    }
    return orden;
  }

  public static parsearPagina(s: string, o: number): number {
    if (s === "" || s === "?" || !s.startsWith("?")) {
      return o;
    }
    s = s.substr(1);

    let paginaMuestra: number | null = null;
    s.split("&").forEach(params => {
      let datos = params.split("=");
      if (datos[0] === this.PAGE_PAGINA) {
        const int = parseInt(datos[1]);
        if (!isNaN(int)) {
          paginaMuestra = int + 1;
        }
      } else {
      }
    });

    return paginaMuestra || o;
  }

  public static parsearMuestra(s: string, o: number): number {
    if (s === "" || s === "?" || !s.startsWith("?")) {
      return o;
    }
    s = s.substr(1);

    let paginaMuestra: number | null = null;
    s.split("&").forEach(params => {
      let datos = params.split("=");
      if (datos[0] === this.PAGE_MUESTRA) {
        const int = parseInt(datos[1]);
        if (!isNaN(int)) {
          paginaMuestra = int;
        }
      } else {
      }
    });

    return paginaMuestra || o;
  }
}