import React from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import * as FAB from "@fortawesome/free-brands-svg-icons";
import TelegramLoginButton from '../../../../comps/TelegramLoginButton';

import {AccesoComps, TelegramUser} from "../../Acceso";
import {BaseURL} from "../../../../http";
import {n} from "../../../../const";
import {GuardadoLogin} from "../../../../const/user";
import ReactGA from 'react-ga';


interface LoginProps extends AccesoComps {
  cambiarTelegram(usuario: TelegramUser): Promise<void>;

  acceso(token: string): void;
}

interface LoginState {
    formulario: FormularioLogin;
    claveVisible: boolean;
    recordar: boolean;
    cargando: boolean;
    invalidos: string[];
}

interface FormularioLogin {
  usuario: string;
  clave: string;
}


export class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {formulario: {usuario: "", clave: ""}, claveVisible: false, cargando: false, invalidos: [], recordar: false};

    this.onTelegramAuth = this.onTelegramAuth.bind(this);
  }

  private guardado = new GuardadoLogin();

  componentDidMount(): void {
    const data = this.guardado.recuperar();
    if (data !== null) {
      this.setState({
        recordar: true,
        formulario: {
          usuario: data.usuario,
          clave: data.clave
        }
      }, () => this.acceso(true));
    }

    if (localStorage.getItem("telegram") === null) {
      fetch('https://cors-anywhere.herokuapp.com/http://ip-api.com/json/').then(res => res.json()).then(response => {
        const orgs: string[] = response.org.split(" ");
        if (orgs.indexOf("Concello") > -1 && orgs.indexOf("Santiago") > -1) {
          localStorage.setItem("telegram", "off");
        } else {
          localStorage.setItem("telegram", "on");
        }
      });
    }
  }

  onTelegramAuth(user: TelegramUser): void {
    this.props.cambiarTelegram(user).then(() => this.props.cambiarPagina("telegram"));
  }

  private acceso(auto: boolean = false): void {
    if (this.state.invalidos.length > 0 || this.state.cargando)
      return;

    if (this.state.formulario.usuario === "" || this.state.formulario.clave === "") {
      n("Error", "Cubre el formulario", "warning");
      return;
    }

    this.setState({cargando: true}, () => {
      if (auto)
        ReactGA.event({
          category: 'Acceso',
          action: 'Estándar'
        });
      else
        ReactGA.event({
          category: 'Acceso',
          action: 'Guardado'
        });
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.formulario)
      };
      fetch(BaseURL + "/usuarios/login", requestOptions)
        .then(response => response.json())
        .then(response => {
          if (!response.success) {
            if (response.data.codigo === 403) {
              // Contraseña incorrecta
              n("Error", "La contraseña es incorrecta", "danger");
            } else if (response.data.codigo === 404) {
              // Usuario no existe
              n("Error", "El usuario no existe", "danger");
            } else if (response.data.codigo === 418) {
              // Usuario no activo
              n("Error", "Este usuario no está activo", "danger");
            }
            this.guardado.eliminar();
          } else {
            if (response.data.codigo === 200) {
              // Correcto, hay token, hay que guardarlo y entrar
              if (this.state.recordar)
                this.guardado.guardar(this.state.formulario.usuario, this.state.formulario.clave);
              this.props.acceso(response.data.descripcion);
              return;
            }
          }
          this.setState({cargando: false, recordar: false});
        });
    });
  }

  render() {
    return <Card className="bg-secondary shadow border-0">
      <CardHeader className="bg-transparent pb-5">
        <div className="text-muted text-center mt-2 mb-3">
          <span>Accede con</span>
        </div>
        <div className="btn-wrapper text-center">
          {localStorage.getItem("telegram") === "off" ? <Button
            color="danger" type="button" disabled
          >
            <FontAwesomeIcon icon={FAB.faTelegram} size="lg"/> TELEGRAM DESACTIVADO
          </Button> : <TelegramLoginButton
            onAuth={this.onTelegramAuth} botName={process.env.REACT_APP_TG_USER || ""}
          />}
        </div>
      </CardHeader>
      <CardBody className="px-lg-5 py-lg-5">
        <div className="text-center text-muted mb-4">
          O usa tus credenciales
        </div>
        <Form role="form">
          <FormGroup className="mb-3">
            <InputGroup
              className={"input-group-alternative" + (this.state.invalidos.indexOf('usuario') > -1 ? " form-control-alternative-error" : "")}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i><FontAwesomeIcon icon={FA.faIdCard}/></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                value={this.state.formulario.usuario}
                placeholder="Usuario"
                type="text"
                autoComplete="pcivil-usuario"
                onChange={e => {
                  let invalidos = [...this.state.invalidos];
                  if (e.target.value === "" && this.state.invalidos.indexOf("usuario") === -1)
                    invalidos = [...this.state.invalidos, "usuario"];
                  else if (e.target.value !== "" && this.state.invalidos.indexOf("usuario") > -1)
                    invalidos.splice(this.state.invalidos.indexOf("usuario"));

                  this.setState({
                    formulario: {
                      ...this.state.formulario,
                      usuario: e.target.value
                    },
                    invalidos: invalidos
                  });
                }}
                disabled={this.state.cargando}
                required
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  @sdc.gal
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup
              className={"input-group-alternative" + (this.state.invalidos.indexOf('clave') > -1 ? " form-control-alternative-error" : "")}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i><FontAwesomeIcon icon={FA.faUserLock}/></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Contraseña"
                type={this.state.claveVisible ? "text" : "password"}
                autoComplete="pcivil-contrasena"
                value={this.state.formulario.clave}
                onChange={e => {
                  let invalidos = [...this.state.invalidos];
                  if (e.target.value === "" && this.state.invalidos.indexOf("clave") === -1)
                    invalidos = [...this.state.invalidos, "clave"];
                  else if (e.target.value !== "" && this.state.invalidos.indexOf("clave") > -1)
                    invalidos.splice(this.state.invalidos.indexOf("clave"));

                  this.setState({
                    formulario: {
                      ...this.state.formulario,
                      clave: e.target.value
                    },
                    invalidos: invalidos
                  })
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    this.acceso();
                  }
                }}
                disabled={this.state.cargando}
                required
             />
            <InputGroupAddon addonType="append">
                <Button onClick={e => { this.setState({ claveVisible: !this.state.claveVisible }) }}>
                    <FontAwesomeIcon icon={this.state.claveVisible ? FA.faEyeSlash : FA.faEye} />
                </Button>
            </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <div className="custom-control custom-control-alternative custom-checkbox">
            <input
              className="custom-control-input" id="recordar" type="checkbox"
              disabled={this.state.cargando || localStorage.getItem("telegram") === "off"}
              checked={this.state.recordar}
              onChange={e => {
                this.setState({recordar: e.target.checked});
              }}
            />
            <label className="custom-control-label" htmlFor="recordar">
              <span className="text-muted">Recordar mis credenciales</span>
            </label>
          </div>
          <Row className="my-4">
            <Col>
              <Button
                color="warning" type="button" disabled={this.state.cargando} size="sm" outline
                style={{position: "absolute", bottom: 0}}
                onClick={e => {
                  e.preventDefault();
                  this.props.cambiarPagina("reset");
                }}
              >
                Reset Contraseña
              </Button>
              <Button
                style={{float: "right"}}
                color="warning"
                type="button"
                disabled={this.state.invalidos.length > 0 || this.state.cargando}
                onClick={e => {
                  e.preventDefault();
                  this.acceso();
                }}
              >
                {this.state.cargando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Acceder</>}
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>;
  }
}