import React from "react";
import {RouteComponentProps} from 'react-router';

import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import ReactDatetime from "react-datetime";
import moment from "moment";

import {AppRoutes, ConcelloSantiago, Tipos} from "../../const";
import {BaseURL, Error as E, Headers} from "../../http";
import {Operativo, OperativoCrear, OperativoLimites} from "../../models";
import {permiso, Permisos} from "../../const/permisos";
import {OPERATIVO} from "../../const/specs";


interface NuevoProps extends RouteComponentProps {
  operativo: boolean;

  cancelar(): void;
}

interface NuevoState {
  operativo: OperativoCrear | null;
  creando: boolean;
  tipo: {
    operativo: boolean;
    colaboracion: boolean;
  };
  mostrarAjustes: boolean;
}


export class Nuevo extends React.Component<NuevoProps, NuevoState> {
  constructor(props: NuevoProps) {
    super(props);
    this.state = {
      operativo: null,
      creando: false,
      tipo: {
        operativo: false,
        colaboracion: false
      },
      mostrarAjustes: false
    };
  }

  private bloqueado = React.createRef<HTMLInputElement>();
  private notas = React.createRef<HTMLInputElement>();

  private valido(): boolean {
    if (this.state.operativo === null)
      return false;
    if (this.state.operativo.nombre === "" || this.state.operativo.descripcion === "")
      return false;
    if (this.state.operativo.nombre.length > OPERATIVO.NOMBRE_MAX || this.state.operativo.descripcion.length > OPERATIVO.DESCRIPCION_MAX)
      return false;
    if (this.state.operativo.horaNido === null)
      return false;
    return this.state.tipo.colaboracion || (permiso(Permisos.gestionOperativos) && this.state.tipo.operativo);

  }

  componentDidUpdate(prevProps: Readonly<NuevoProps>, prevState: Readonly<NuevoState>, snapshot?: any): void {
    if (!this.props.operativo) {
      if (this.state.operativo !== null) {
        this.setState({operativo: null, tipo: {operativo: false, colaboracion: false}});
      }
      return;
    }
    if (this.state.operativo === null)
      this.setState({
        operativo: {
          nombre: "",
          descripcion: "",
          horaNido: moment.utc(),
          tipo: !permiso(Permisos.gestionOperativos) ? {
            id: Tipos.Colaboraciones,
            nombre: "Colaboración"
          } : undefined,
        notas: false,
        fechaPublicacion: null,
        }
      });
  }

  private acabar(json: Operativo) {
    this.props.cancelar();
    this.setState({creando: false}, () => this.props.history.push(AppRoutes.Operativos + "/" + json.uuid));
  }

  private crear() {
    if (!this.valido())
      return;
    // @ts-ignore
    this.setState({creando: true, operativo: {...this.state.operativo, notas: this.notas.current?.checked || false}},
      () => fetch(BaseURL + "/operativos/alta", {
        headers: Headers,
        method: "POST",
        body: JSON.stringify(this.state.operativo)
      })
        .then(res => res.json())
        .then(json => {
          if (this.bloqueado.current?.checked) {
            fetch(BaseURL + "/operativos/" + json.uuid + "/limites", {headers: Headers})
              .then(r => r.json())
              .then((limites: OperativoLimites) => {
                limites.bloqueado = true;
                fetch(BaseURL + "/operativos/" + json.uuid + "/limites", {
                  headers: Headers,
                  method: "POST",
                  body: JSON.stringify(limites)
                })
                  .then(() => this.acabar(json));
              });
          } else {
            this.acabar(json);
          }
        })
        .catch(error => E(error)));
  }

  private seleccionado = () => this.state.tipo.colaboracion || this.state.tipo.operativo;

  render() {
    return <Modal
      className="modal-dialog-centered modal-lg"
      isOpen={this.props.operativo} toggle={() => this.props.cancelar()}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="modal-title-default">
          {this.state.tipo.colaboracion ? "Nueva colaboración" : "Nuevo operativo"}
        </h4>
        <button aria-label="Cerrar"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.cancelar()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Nav
          className="nav-fill flex-column flex-md-row mb-4"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          {permiso(Permisos.gestionOperativos) ? <NavItem>
            <NavLink
              style={{
                backgroundColor: (this.state.tipo.operativo ? "var(--warning)" : undefined),
                color: (this.state.tipo.operativo ? undefined : "var(--warning)")
              }}
              aria-selected={this.state.tipo.operativo}
              className={"mb-sm-3 mb-md-0" + (this.state.tipo.operativo ? " active" : (!this.seleccionado() ? " highlight" : ""))}
              onClick={() => {
                if (this.state.operativo === null)
                  return;
                this.setState({
                  operativo: {
                    ...this.state.operativo,
                    tipo: undefined,
                    concello: undefined,
                    horaInicio: undefined
                  },
                  tipo: {operativo: true, colaboracion: false}
                })
              }}
              role="tab"
              href="#"
            >
              <FontAwesomeIcon icon={FA.faCompass}/> Operativo
            </NavLink>
          </NavItem> : <></>}
          <NavItem>
            <NavLink
              style={{
                backgroundColor: (this.state.tipo.colaboracion ? "var(--warning)" : undefined),
                color: (this.state.tipo.colaboracion ? undefined : "var(--warning)")
              }}
              aria-selected={this.state.tipo.colaboracion}
              className={"mb-sm-3 mb-md-0" + (this.state.tipo.colaboracion ? " active" : (!this.seleccionado() ? " highlight" : ""))}
              onClick={() => {
                if (this.state.operativo === null)
                  return;
                this.setState({
                  operativo: {
                    ...this.state.operativo, tipo: {
                      id: Tipos.Colaboraciones,
                      nombre: "Colaboración"
                    },
                    concello: {
                      id: ConcelloSantiago,
                      nombre: "Santiago de Compostela"
                    },
                    horaInicio: this.state.operativo.horaNido || undefined
                  },
                  tipo: {operativo: false, colaboracion: true}
                })
              }}
              role="tab"
              href="#"
            >
              <FontAwesomeIcon icon={FA.faHandshake}/> Colaboración
            </NavLink>
          </NavItem>
        </Nav>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-nombre">
                Nombre
              </label>
              <Input
                maxLength={OPERATIVO.NOMBRE_MAX}
                disabled={this.state.creando || (!this.state.tipo.operativo && !this.state.tipo.colaboracion)}
                className="form-control-alternative"
                value={this.state.operativo?.nombre}
                id="input-nombre"
                placeholder="Nombre"
                type="text"
                onChange={e => {
                  if (this.state.operativo === null)
                    return;
                  this.setState({
                    operativo: {...this.state.operativo, nombre: e.target.value}
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-descripcion">
                Descripción
              </label>
              <Input
                maxLength={OPERATIVO.DESCRIPCION_MAX}
                disabled={this.state.creando || (!this.state.tipo.operativo && !this.state.tipo.colaboracion)}
                className="form-control-alternative"
                id="input-descripcion"
                placeholder="Descripción corta"
                value={this.state.operativo?.descripcion}
                rows="2"
                type="textarea"
                onChange={e => {
                  if (this.state.operativo === null)
                    return;
                  this.setState({
                    operativo: {...this.state.operativo, descripcion: e.target.value}
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-horaNido">
                Hora Nido
              </label>
              <InputGroup className="input-group-alternative" id="input-horaNido">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={FA.faUserClock}/>
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Hora Nido",
                    disabled: this.state.creando || (!this.state.tipo.operativo && !this.state.tipo.colaboracion)
                  }}
                  value={this.state.operativo?.horaNido?.local()}
                  onChange={(e: any) => {
                    if (this.state.operativo === null)
                      return;
                    if (typeof e === typeof "" || e === null || e === undefined)
                      e = null;
                    if (e !== null)
                      e.set('second', 0).set('millisecond', 0);
                    this.setState({
                      operativo: {
                        ...this.state.operativo,
                        horaNido: e,
                        horaInicio: this.state.tipo.colaboracion ? e : undefined
                      }
                    });
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        {permiso(Permisos.gestionOperativos) ? <h6
          className="heading-small text-muted mb-4"
          style={{cursor: 'pointer'}}
          onClick={() => this.setState({mostrarAjustes: !this.state.mostrarAjustes})}
        >
          <FontAwesomeIcon
            icon={this.state.mostrarAjustes ? FA.faChevronDown : FA.faChevronRight}
          /> Ajustes
        </h6> : <></>}
        <div style={{display: this.state.mostrarAjustes ? "block" : "none"}} className="pl-lg-4">
          <Row style={{paddingTop: 5, cursor: "pointer"}} onClick={() => {
            if (this.bloqueado.current) {
              this.bloqueado.current.checked = !this.bloqueado.current.checked;
            }
          }}>
            <Col xs="2" sm="2" md="2" lg="1" xl="1">
              <label className="custom-toggle">
                <input type="checkbox" onClick={e => e.preventDefault()} ref={this.bloqueado}/>
                <span className="custom-toggle-slider rounded-circle"/>
              </label>
            </Col>
            <Col xs="10" sm="10" md="10" lg="11" xl="11">
              Bloquear inscripciones
            </Col>
          </Row>
          <Row style={{paddingTop: 5, cursor: "pointer"}} onClick={() => {
            if (this.notas.current) {
              this.notas.current.checked = !this.notas.current.checked;
            }
          }}>
            <Col xs="2" sm="2" md="2" lg="1" xl="1">
              <label className="custom-toggle">
                <input type="checkbox" onClick={e => e.preventDefault()} ref={this.notas}/>
                <span className="custom-toggle-slider rounded-circle"/>
              </label>
            </Col>
            <Col xs="10" sm="10" md="10" lg="11" xl="11">
              Permitir notas de voluntarios
            </Col>
        </Row>
        <Row >
            <FormGroup>
                <InputGroup className="input-group-alternative" id="input-fechaPublicacion">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <FontAwesomeIcon icon={FA.faUserClock} />
                        </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                        inputProps={{
                            placeholder: "Fecha de publicación",
                            disabled: this.state.creando || (!this.state.tipo.operativo && !this.state.tipo.colaboracion)
                        }}
                        value={this.state.operativo?.fechaPublicacion?.local()}
                        timeFormat={false}
                        onChange={(e: any) => {
                            if (this.state.operativo === null)
                                return;
                            if (typeof e === typeof "" || e === null || e === undefined)
                                e = null;
                            if (e !== null)
                                e.set('second', 0).set('millisecond', 0);
                            this.setState({
                                operativo: {
                                    ...this.state.operativo,
                                    fechaPublicacion: e,
                                }
                            });
                        }}
                    />
                </InputGroup>
            </FormGroup>
        </Row>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          color="link" data-dismiss="modal" type="button" className="text-warning"
          onClick={() => this.props.cancelar()} disabled={this.state.creando}
        >
          Cerrar
        </Button>
        <Button
          className="ml-auto" color="warning" type="button"
          onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
        >
          {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
        </Button>
      </div>
    </Modal>;
  }
}
