import React from "react";

import {Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FAB from "@fortawesome/free-brands-svg-icons";
import {Usuario} from "../../const/user";

export const Footer: React.FC = () => {
    return (
        <>
            <footer className="py-5">
                <Container>
                    <Row className="align-items-center justify-content-xl-between">
                        <Col xl="4" className="order-0 order-lg-0">
                            <div className="copyright text-center text-xl-left text-muted">
                                <span className="font-weight-bold ml-1">
                                    Protección Civil Santiago
                                </span>
                                {" "}© {new Date().getFullYear()}
                            </div>
                        </Col>
                        <Col xl="4" className="order-2 order-xl-1">
                            <Nav className="nav-footer justify-content-center">
                                <NavItem>
                                    <NavLink href="https://vercel.com?utm_source=pc-santiago" target="_blank">
                                        <img
                                            alt="..."
                                            src={require('../../assets/img/vercel.svg')}
                                            style={{
                                                height: "2.25rem",
                                                filter: "invert(" + (new Usuario().voluntario() !== null ? "0" : "1") + ")"
                                            }}
                                        />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col xl="4" className="order-1 order-xl-2">
                            <Nav className="nav-footer justify-content-center justify-content-xl-end">
                                <NavItem>
                                    <NavLink href="https://formacion.pc.sdc.gal" target="_blank">
                                        Formación
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://proteccioncivil.sdc.gal" target="_blank">
                                        Página Web
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://facebook.com/avpc.santiagodecompostela" target="_blank">
                                        <FontAwesomeIcon icon={FAB.faFacebook} size="lg"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://twitter.com/pc_compostela" target="_blank">
                                        <FontAwesomeIcon icon={FAB.faTwitter} size="lg"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://instagram.com/pc_compostela" target="_blank">
                                        <FontAwesomeIcon icon={FAB.faInstagram} size="lg"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="https://t.me/pc_compostela" target="_blank">
                                        <FontAwesomeIcon icon={FAB.faTelegram} size="lg"/>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};
