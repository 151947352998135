import {n} from "../const";
import {Usuario} from "../const/user";
import moment from "moment-timezone";


const secure: boolean = (process.env.REACT_APP_LORO_SECURE || "").toLowerCase() === "true";
const server: string = process.env.REACT_APP_LORO_SERVER || "localhost:8080";

export const BaseURL = "http" + (secure ? "s" : "") + "://" + server;


export const Error = (error: any) => {
    console.error(error);
    n("Error al procesar solicitud", "Esto puede ser porque la sesión haya expirado. Por favor, " +
        "actualiza la página.", "danger", 10000);
};


const token = new Usuario().token();
export const Headers: any = {
    'Content-Type': 'application/json',
    'Authorization': token !== null ? "Bearer " + token : undefined,
    // Cosas para Backend
    'Loro-Timezone': moment.tz.guess(),
    'Loro-Version': process.env.REACT_APP_VERSION
};