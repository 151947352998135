import React from "react";
import { RouteComponentProps } from "react-router";
import { Modal, Row, Col, InputGroup,
         Button, Table, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { Cursos, CursosExclusiones } from "../../models/Formacion";
import { Voluntario } from "../../models/Voluntario";
import Select from "react-select";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import { BaseURL, Headers } from "../../http";
import { AppRoutes, n } from "../../const";


interface ExclusionesProps extends RouteComponentProps {
    mostrar: boolean;
    cancelar(): void;
}

interface ExclusionesState {
    voluntarios: Voluntario[];
    cursos: Cursos[];
    exclusiones: CursosExclusiones[];
    voluntarioSeleccionado: Voluntario | null;
    cursoSeleccionado: Cursos | null;
}

export class Exclusiones extends React.Component<ExclusionesProps, ExclusionesState> {

    constructor(props: ExclusionesProps) {
        super(props);
        this.state = {
            voluntarios: [],
            cursos: [],
            exclusiones: [],
            voluntarioSeleccionado: null,
            cursoSeleccionado: null,
        }
    }

    componentDidMount() {
        fetch(BaseURL + "/voluntarios/total", { headers: Headers })
            .then(res => res.text())
            .then(res => fetch(BaseURL + "/voluntarios?orden=yago&baja=0&size=" + res, { headers: Headers })
                .then(res => res.json())
                .then(voluntarios => {
                    this.setState({ voluntarios: voluntarios });
                }));
        //Recogemos los cursos básicos: 1
        fetch(BaseURL + "/cursos/1", { headers: Headers })
            .then(res => res.json())
            .then(cursos => {
                cursos.fecha = moment.utc(cursos.fecha);
                this.setState({ cursos: cursos });
            });
        fetch(BaseURL + "/cursos/exclusiones", { headers: Headers })
            .then(res => res.json())
            .then(exclusiones => this.setState({ exclusiones: exclusiones }));

    }

    private nuevaExclusion() {
        if (this.state.voluntarioSeleccionado === null || this.state.cursoSeleccionado == null) {
            return;
        }

        let exclusion: CursosExclusiones = {
            voluntario: this.state.voluntarioSeleccionado,
            curso: this.state.cursoSeleccionado,
        }

        this.state.exclusiones.forEach(valor => {
            if (exclusion.curso.id === valor.curso.id && exclusion.voluntario.id === valor.voluntario.id) {
                n("Aviso", "Convalidación ya registrada", "warning");
                return;
            }
        });


        this.setState({ exclusiones: [...this.state.exclusiones, exclusion], voluntarioSeleccionado: null, cursoSeleccionado: null });
    }

    private guardar() {
        fetch(BaseURL + "/cursos/exclusiones", {
            headers: Headers,
            method: "PUT",
            body: JSON.stringify(this.state.exclusiones)
        }).catch(() => {
            n("Error", "Hubo un error al guardar los datos de los voluntarios", "danger");
        });
        this.props.cancelar();
    }

    public eliminarExclusion = (voluntario: string) => {
        let filtrado = this.state.exclusiones?.filter(vol => vol.voluntario.yago !== voluntario);
        if (filtrado === undefined) {
            this.setState({ exclusiones: [] });
        } else {
            this.setState({ exclusiones: filtrado });
        }
    }

    private formatearVoluntarios() {
        return this.state.voluntarios?.map(voluntario => ({ value: voluntario.id, label: voluntario.yago + " - " + voluntario.nombre }));
    }

    private formatearCursos() {
        return this.state.cursos?.map(curso => ({ value: curso.id, label: curso.nombre }));
    }


    render() {
        return <Modal
          className="modal-dialog-centered modal-xl" isOpen={this.props.mostrar}
          toggle={() => this.props.cancelar()}
        >
            <div className="modal-header">
                <h4 className="modal-title" id="modal-title-default">Convalidaciones</h4>
                <button aria-label="Cerrar"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.cancelar()}>
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
                <Col>
                    <Row>
                        <Col xs="12" md="5">
                            <InputGroup>
                                <label className="form-control-label" htmlFor="input-nombre">
                                    Voluntarios
                                    </label>
                                <Select
                                    name="voluntarios"
                                    value={this.state.voluntarioSeleccionado === null ? null : (() => {
                                        return {
                                            value: this.state.voluntarioSeleccionado.id,
                                            label: (this.state.voluntarioSeleccionado.yago + " - " + this.state.voluntarioSeleccionado.nombre)
                                        }
                                    })()}
                                    options={this.formatearVoluntarios()}
                                    className="select-input"
                                    placeholder="Ninguno"
                                    classNamePrefix="select-input-prefix"
                                    onChange={value => {
                                        let voluntario = this.state.voluntarios.filter(voluntario => voluntario.id === value?.value)[0];
                                        if (voluntario === undefined) return;
                                        this.setState({ voluntarioSeleccionado: voluntario });
                                    }}
                                    noOptionsMessage={() => "..."}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="12" md="5">
                            <InputGroup>
                                <label className="form-control-label" htmlFor="input-nombre">
                                    Cursos
                                </label>
                                <Select
                                    name="cursos"
                                    value={this.state.cursoSeleccionado === null ? null : (() => {
                                        return {
                                            value: this.state.cursoSeleccionado.id,
                                            label: this.state.cursoSeleccionado.nombre
                                        }
                                    })()}
                                    options={this.formatearCursos()}
                                    className="select-input"
                                    placeholder="Ninguno"
                                    classNamePrefix="select-input-prefix"
                                    onChange={value => {
                                        let curso = this.state.cursos.filter(curso => curso.id === value?.value)[0];
                                        if (curso === undefined) return;
                                        this.setState({ cursoSeleccionado: curso });
                                    }}
                                    noOptionsMessage={() => "..."}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="12" md="1" style={{ paddingTop: "31px" }}>
                            <Button
                                color="primary"
                                onClick={() => this.nuevaExclusion()}
                            >
                                +
                            </Button>
                        </Col>
                    </Row>
                </Col>
                {/** Tabla de resultados */}
                <div className="modal-listados" style={{ paddingTop: "15px" }}>
                    <Col>
                        {this.state.exclusiones.length === 0 ?
                            <h5>Sin convalidaciones. Todos los voluntarios deben realizar los cursos básicos</h5>
                            :
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        {/* Avatar */}
                                        <th scope="col" />
                                        {/* Voluntario */}
                                        <th scope="col">{" "}Voluntario</th>
                                        {/* Curso */}
                                        <th scope="col">{" "}Curso</th>
                                        {/* Borrado */}
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.exclusiones.map((prop, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <div className="avatar-group" style={{ verticalAlign: "center" }}>
                                                        <Link
                                                            className="avatar avatar-sm" onClick={(e: any) => e.preventDefault()}
                                                            id={"opVoluntario" + key + "Avatar"}
                                                            to={AppRoutes.Voluntarios + "/" + prop.voluntario.uuid}
                                                        >
                                                            <img alt="..." className="rounded-circle" src={prop.voluntario.avatar} />
                                                        </Link>
                                                        <UncontrolledTooltip delay={0} target={"opVoluntario" + key + "Avatar"}>
                                                            {prop.voluntario.nombre + " " + prop.voluntario.apellidos}
                                                        </UncontrolledTooltip>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Button
                                                        tag={Link} to={AppRoutes.Voluntarios + "/" + prop.voluntario.uuid}
                                                        size="sm" type="button" color="secondary" outline
                                                    >
                                                        <span className="mb-0 text-sm">
                                                            {prop.voluntario.nombre + " " + prop.voluntario.apellidos}
                                                        </span>
                                                        <small>{" " + prop.voluntario.yago}</small>
                                                    </Button>
                                                </td>
                                                <td>{prop.curso.nombre}</td>
                                                <td>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.eliminarExclusion(prop.voluntario.yago);
                                                        }}
                                                        id={"fVoluntario" + key + "CEliminar"}
                                                        size="sm" type="button" color="danger" data-placement="top"
                                                    >
                                                        <FontAwesomeIcon icon={FA.faTrashAlt} />
                                                    </Button>
                                                    <UncontrolledTooltip delay={0} placement="top" target={"fVoluntario" + key + "CEliminar"}>
                                                        Eliminar
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </div>
            </div>

            <div className="modal-footer">
                <Button
                    color="link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.props.cancelar()}
                >Cancelar </Button>
                <Button
                    color="primary"
                    type="button"
                    disabled={false}
                    onClick={() => this.guardar()}
                >Guardar</Button>
            </div>
        </Modal>;
    }

}

export default Exclusiones;